<template>
    <v-dialog v-model="privacyDialogActive" @keydown.esc="privacyDialogActive = false">
        <template #activator="{ on }">
            <a href="#" class="primary" @click="on">Privacy verklaring</a>
        </template>

        <v-card large>
            <v-card-title>Privacy verklaring</v-card-title>
            <v-card-subtitle>Versie 1.0 - juni 2021</v-card-subtitle>
            <v-card-body scroll-gradient>
                <h3>Privacyverklaring Niet Pluis App – Haaglanden Medisch Centrum</h3>
                <p>
                    Deze verklaring is van toepassing op de Niet Pluis App, een applicatie van Stichting Haaglanden Medisch Centrum (hierna:
                    “HMC”) ten behoeve van aangesloten zorgaanbieders die de app in de praktijk gebruiken. Deze zorgaanbieders en HMC gaan
                    zorgvuldig met uw privacy om. Bij de ontwikkeling van de Niet Pluis App is de Algemene Verordening Gegevensbescherming
                    (hierna: “AVG”) dan ook als uitgangspunt genomen.
                </p>
                <p>
                    Persoonsgegevens zijn alle gegevens die iets over u zeggen of te herleiden zijn naar uw persoon. Bijvoorbeeld uw naam,
                    adres of telefoonnummer. Alles wat met persoonsgegevens wordt gedaan, bijvoorbeeld het opslaan, kopiëren, met andere
                    delen, wordt ‘verwerken’ genoemd.
                </p>
                <p>
                    Deze privacyverklaring heeft alleen betrekking op de verwerking en bescherming van persoonsgegevens in de Niet Pluis App
                    door aangesloten zorgaanbieders die gebruik maken van de Niet Pluis App in de rol van Verwerkingsverantwoordelijke en
                    het HMC in de rol van leverancier en Verwerker.
                </p>
                <p>
                    Als je vragen hebt, bijvoorbeeld over deze verklaring, kun je altijd contact met de zorgaanbieder voor wie je werkt of
                    de zorgaanbieder bij wie je onder behandeling bent.
                </p>

                <h4>Doelen waarvoor (persoons)gegevens kunnen worden verwerkt</h4>
                <p>In de Niet Pluis App worden voor de volgende doelen persoonsgegevens verwerkt door aangesloten zorgaanbieders.</p>
                <p>Persoonsgegevens van medewerkers:</p>
                <ul>
                    <li>Het aanmaken van een profiel in de Niet Pluis App;</li>
                    <li>Het loggen (bijhouden en kunnen traceren) van activiteiten in de Niet Pluis App;</li>
                    <li>Communicatie tussen (zorg)professionals.</li>
                </ul>
                <p>Persoonsgegevens van cliënten/patiënten:</p>
                <ul>
                    <li>Het kunnen identificeren van de cliënt/patiënt;</li>
                    <li>Het verlenen van zorg.</li>
                </ul>

                <h4>Op basis waarvan mogen we uw persoonsgegevens verwerken?</h4>
                <p>Uw gegevens mogen alleen verwerkt worden als daar een reden (‘grondslag’) voor bestaat, zoals:</p>
                <p>Grondslag voor het verwerken van persoonsgegevens van medewerkers:</p>
                <ul>
                    <li>
                        Gerechtvaardigd belang van de aangesloten zorgaanbieder: het voorzien in communicatiesoftware ten behoeve van de
                        werkzaamheden.
                    </li>
                </ul>
                <p>Grondslag voor het verwerken van persoonsgegevens van cliënten/patiënten:</p>
                <ul>
                    <li>Het uitvoeren van een overeenkomst: de behandelovereenkomst.</li>
                </ul>

                <h4>Categorieën persoonsgegevens die kunnen worden verwerkt</h4>
                <p>Persoonsgegevens van medewerkers:</p>
                <ul>
                    <li>Algemene contactgegevens (zoals uw naam en e-mailadres)</li>
                </ul>
                <p>Persoonsgegevens van cliënten/patiënten:</p>
                <ul>
                    <li>Algemene gegevens (zoals uw naam, adres, geboortedatum en geslacht)</li>
                    <li>Gegevens over uw gezondheid</li>
                </ul>

                <h4>Toegang tot uw persoonsgegevens</h4>
                <p>
                    Alleen medewerkers die rechtstreeks betrokken zijn bij de uitvoering van uw behandelingsovereenkomst hebben toegang tot
                    de gegevens waarvan zij voor hun werkzaamheden kennis moeten nemen. Uiteraard hebben zij een geheimhoudingsplicht.
                </p>

                <h4>Rechten met betrekking tot uw persoonsgegevens</h4>
                <p>
                    U heeft verschillende rechten met betrekking tot de verwerking van uw persoonsgegevens. De rechten die het meest van
                    toepassing zijn in de zorg, zetten we hier voor u op een rijtje.
                </p>
                <ul>
                    <li>
                        Recht op informatie. Je hebt het recht te worden geïnformeerd over welke persoonsgegevens wij van jou verwerken en
                        hoe we dit doen. Aan dit recht hebben we invulling gegeven door deze privacyverklaring op te stellen en beschikbaar
                        te stellen.
                    </li>
                    <li>
                        Recht op inzage en recht op gegevensoverdracht. U heeft het recht op inzage in de persoonsgegevens die wij van u
                        verwerken en die persoonsgegevens te ontvangen.
                    </li>
                    <li>
                        Recht op correctie. U kunt een verzoek doen om gegevens te corrigeren als deze onjuist zijn. Bijvoorbeeld wanneer uw
                        adresgegevens fout vermeld staan. Alleen gegevens die feitelijk onjuist zijn, komen voor correctie in aanmerking.
                    </li>
                    <li>
                        Recht op vernietiging of beperking. U kunt ons verzoeken (een deel van) uw gegevens te laten vernietigen of de
                        verwerking door ons te staken (te beperken). Het vernietiging van (een deel van) uw medische dossier kan er in
                        sommige gevallen toe leiden dat uw zorgverlener in HMC u niet meer goed kan behandelen.
                    </li>
                    <li>Recht van bezwaar. U heeft het recht om bezwaar te maken tegen gebruik van uw gegevens.</li>
                </ul>
                <p>
                    Als je een beroep wilt doen op jouw rechten, kun je contact opnemen met de zorgaanbieder voor wie je werkt of de
                    zorgaanbieder bij wie je onder behandeling bent.
                </p>

                <h4>Beveiliging van uw persoonsgegevens</h4>
                <p>
                    Uw persoonsgegevens worden beveiligd volgens wet- en regelgeving en beveiligingsstandaarden voor de zorg (NEN 7510 en
                    ISO 27001). Met alle partijen zijn hier duidelijke contractuele afspraken over gemaakt.
                </p>
                <p>
                    Als er toch iets niet goed gegaan is met de bescherming van uw persoonsgegevens en er een zogenaamd ‘datalek’ is
                    ontstaan dan onderzoekt de aangesloten zorgaanbieder hoe dit heeft kunnen gebeuren. Ernstige datalekken worden ook
                    gemeld bij de Autoriteit Persoonsgegevens. Als het datalek ook negatieve gevolgen kan hebben voor uw persoonlijke
                    levenssfeer wordt u daar zo snel mogelijk over geïnformeerd. Bij het opstellen van nieuw beleid worden privacy risico’s
                    nadrukkelijk meegewogen en wordt gekozen voor oplossingen waarbij uw privacy zoveel mogelijk is gegarandeerd.
                </p>

                <h4>Bewaartermijnen</h4>
                <p>
                    De persoonsgegevens in uw medisch dossier worden bewaard tot 20 jaar na uw laatste behandelcontact met de desbetreffende
                    zorgaanbieder. Overige persoonsgegevens worden bewaard tot ze niet langer nodig zijn voor de eerder genoemde doelen.
                </p>

                <h4>Vragen</h4>
                <p>
                    Als u vragen heeft over de manier waarop uw privacy wordt beschermd of daarover een klacht heeft, kunt u contact opnemen
                    met de Functionaris Gegevensbescherming van de zorgaanbieder waar je u werkzaam of onder behandeling bent.
                </p>
                <p>
                    U heeft ook de mogelijkheid een klacht in te dienen bij de
                    <a
                        href="https://www.autoriteitpersoonsgegevens.nl/nl/zelf-doen/gebruik-uw-privacyrechten/klacht-melden-bij-de-ap"
                        target="_blank"
                        class="primary underline"
                        rel="noreferrer noopener"
                        >Autoriteit Persoonsgegevens</a
                    >. Uiteraard stellen wij het op prijs als u ons eerst gelegenheid geeft een reactie te geven op uw klacht.
                </p>
            </v-card-body>
            <v-card-actions>
                <button type="button" secondary @click="privacyDialogActive = !privacyDialogActive">Sluiten</button>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script setup lang="ts">
    import { VCard, VCardActions, VCardBody, VCardSubtitle, VCardTitle } from '@/components/v-card';
    import { ref } from 'vue';

    const privacyDialogActive = ref(false);
</script>

<style scoped lang="scss">
    .v-card {
        display: grid;
        grid-template-rows: auto auto 1fr auto;
    }

    ul {
        list-style: none;
        margin-left: 0.5rem;
        padding-left: 1em;
        text-indent: 0;
    }

    ul:not(.nav) li {
        background-image: url('@/assets/icons/cross.svg');
        background-position: 0 1px;
        background-repeat: no-repeat;
        background-size: 1rem;
        margin-bottom: 5px;
        padding-left: 2rem;
        text-align: left;
    }

    .v-card h4 {
        margin-top: 2rem;

        + p {
            margin-top: 0.5rem;
        }
    }
</style>
