import type { ResultResponse } from '@/interfaces/Response';
import type { CreateOrganizationDTO, Homecare, Organization, OrganizationType, Practice } from '@nietpluis/common/interfaces/organisation';
import axios, { type AxiosResponse } from 'axios';
import { defineStore } from 'pinia';

export const usePracticeStore = defineStore('practice', {
    state: () => ({
        practices: [] as Practice[],
        organization: undefined as Organization | undefined,
        homecareOrganizations: [] as Homecare[],
        results: [] as Organization[],
    }),
    getters: {},
    actions: {
        async fetchOrganizations(options: { type: OrganizationType }): Promise<void> {
            const { data }: { data: ResultResponse<Organization> } = await axios.get(`/organization?type=${options.type}`);

            if (options.type === 'practice') {
                this.practices = data.results as Practice[];
            } else {
                this.homecareOrganizations = data.results as Homecare[];
            }
        },

        async fetchOrganizationById(id: string): Promise<void> {
            if (!id) {
                throw Error('No organization id given');
            }

            const foundPractice = this.practices.find((practice) => practice.id === id);
            const foundHomecare = this.homecareOrganizations.find((homecare) => homecare.id === id);

            if (!foundPractice && !foundHomecare) {
                const { data }: { data: Organization } = await axios.get(`/organization/${id}`);
                this.organization = data;
            } else {
                this.organization = foundPractice ?? foundHomecare;
            }
        },

        async findOrganizationByName(query: { name: string }): Promise<void> {
            this.results = [];
            const params = {
                name: query.name,
            };

            const { data }: { data: ResultResponse<Organization> } = await axios.get('/organization/find', { params });

            this.results = data.results;
        },

        async create(createDTO: CreateOrganizationDTO): Promise<AxiosResponse> {
            // Should do checks
            return axios.post('/organization', createDTO);
        },
    },
});
