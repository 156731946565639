<template>
    <v-card class="modal" large id="new-report">
        <v-card-title class="header">
            <div class="scroll-indicator">
                <span class="si-subject" :class="{ 'si-active': reportSubject }">{{ $t('reports.subject') }}</span>
                <span class="si-report" :class="{ 'si-active': reportDescription }">{{ $t('reports.report') }}</span>
                <span class="si-patient" :class="{ 'si-active': reportPatient }">{{ $t('reports.patient-alternative') }}</span>
            </div>

            <div class="progress">
                <progress id="file" :value="progress" max="100"></progress>
            </div>
        </v-card-title>
        <v-card-body class="container scrollGradient">
            <div class="row subject">
                <h2>{{ $t('dialogs.new-report.what-subject') }}</h2>
                <div class="report-subject__wrapper">
                    <div
                        v-for="subject in subjects"
                        class="report-subject"
                        :class="{ 'report-subject--active': reportSubject === subject }"
                        @click="setSubject(subject)">
                        <ReportSubject :subject="subject" />
                        <span class="report-subject__title">{{ subject }}</span>
                    </div>
                </div>
            </div>

            <div class="row report">
                <h2>{{ $t('dialogs.new-report.describe-report') }}</h2>
                <textarea v-model="reportDescription" :placeholder="$t('dialogs.new-report.report-tip')" @blur="checkProgress"> </textarea>
            </div>

            <div class="row patient">
                <h2>{{ $t('dialogs.new-report.patient-data') }}</h2>

                <div class="input-wrapper">
                    <AutoComplete
                        :placeholder="$t('person.lastname')"
                        name="patient-name"
                        :source="patientStore.patients"
                        :spellcheck="false"
                        autofocus
                        @input="updatePatient" />
                    <!-- <input id="lastname" v-model="newReport.search.lastName" type="text" placeholder="Achternaam" @blur="findPatient" />
                    <input id="dob" v-model="newReport.search.dob" type="date" placeholder="Geboortedatum" @change="findPatient" /> -->
                    <NewContactButton type="patient-alternative" text :organization="currentUser.user?.organization?.id" />
                </div>

                <div v-if="reportPatient?.id" class="results">
                    <div>{{ $t('dialogs.new-report.selected-patient') }}</div>
                    <div class="result result-active">
                        <Badge color="purple" small>
                            {{ dateOfBirth(reportPatient.dob) }}
                        </Badge>
                        <span class="name">{{ useFullname(reportPatient, { salutation: true }) }}</span>
                    </div>
                </div>
            </div>
        </v-card-body>
        <footer>
            <v-card-actions class="actions">
                <button type="button" secondary @click="cancel">{{ $t('dialogs.cancel') }}</button>
                <button type="submit" @click="handleSubmit">{{ $t('dialogs.submit') }}</button>
            </v-card-actions>
        </footer>
        <Loader :state="state" />
    </v-card>
</template>

<script setup lang="ts">
    import ReportSubject from '@/components/Icons/ReportSubject.vue';
    import Loader, { type LoadingState } from '@/components/Loader.vue';
    import { VCard, VCardActions, VCardBody, VCardTitle } from '@/components/v-card';
    import { formatDate } from '@/composables/use-time-format';
    import useFullname from '@/composables/useFullname';
    import { UPDATE_MODEL_VALUE } from '@/constants/events';
    import { useCurrentUserStore } from '@/stores/currentUser.store';
    import { useMainStore } from '@/stores/main.store';
    import { useNewReportStore } from '@/stores/newReport.store';
    import { usePatientStore } from '@/stores/patient.store';
    import { useReportStore } from '@/stores/report.store';
    import type { Patient } from '@nietpluis/common/interfaces/person';
    import type { CreateReportDTO } from '@nietpluis/common/interfaces/report';
    import type { AxiosError } from 'axios';
    import { onMounted, ref } from 'vue';
    import { useRouter } from 'vue-router';

    const mainStore = useMainStore();
    const newReport = useNewReportStore();
    const reports = useReportStore();
    const router = useRouter();
    const currentUser = useCurrentUserStore();
    const patientStore = usePatientStore();

    const emit = defineEmits(['cancel:report', UPDATE_MODEL_VALUE]);

    onMounted(async () => {
        checkProgress();
        await patientStore.fetchPatients({ organizationId: currentUser.user?.organization?.id });
    });

    const state = ref<LoadingState>('idle');
    const progress = ref(0);

    const subjects = ['Medicatie', 'Somber', 'In de war', 'Duizelig', 'Eten', 'Gevallen', 'Benauwd', 'Overig'];

    type GQLHeader = {
        key: string;
        value: string;
        active: boolean;
    };

    const header = ref<GQLHeader[]>([
        {
            key: 'Content-Type',
            value: 'application/json',
            active: true,
        },
    ]);

    const reportSubject = ref('');
    const reportDescription = ref('');
    const reportPatient = ref({} as Patient);

    const setSubject = (newSubject: string) => {
        reportSubject.value = newSubject;

        checkProgress();
    };

    const dateOfBirth = (dob: Date | undefined): string => formatDate(dob);

    const selectPatient = (patient: Patient): void => {
        console.log(patient);
        if (reportPatient.value.id !== patient.id) {
            reportPatient.value = patient;
        } else {
            reportPatient.value = {} as Patient;
        }

        checkProgress();
    };

    const findPatient = async (): Promise<void> => {
        if (!newReport.search.lastName || !newReport.search.dob) {
            return;
        }

        newReport.data.patient = {} as Patient;
        await newReport.getPatientByLastNameAndDOB({ lastName: newReport.search.lastName, dob: newReport.search.dob });

        if (newReport.results.length === 1) {
            selectPatient(newReport.results[0] as Patient);
        }
    };

    const updatePatient = (value: any): void => {
        const foundPatient = patientStore.patients.find((patient) => patient.id === value);
        foundPatient && selectPatient(foundPatient as Patient);
    };

    const checkProgress = (): void => {
        if (reportSubject.value) {
            progress.value = 33;

            if (reportDescription.value) {
                progress.value = 66;

                if (reportPatient.value) {
                    progress.value = 100;
                }
            }
        }
    };

    const handleSubmit = async (): Promise<void> => {
        state.value = 'loading';

        if (!reportPatient) {
            state.value = 'idle';
            return;
        }

        const reportDTO: CreateReportDTO = {
            subject: reportSubject.value,
            reportText: reportDescription.value,
            patientId: reportPatient.value.id,
        };

        try {
            const result = await newReport.create(reportDTO);

            if (result) {
                state.value = 'success';

                await reports.fetchReports('open');

                newReport.$reset();
                router.push({ name: 'Melding', params: { id: result.patient.id, reportId: result.id } });
            }

            state.value = 'success';
        } catch (error) {
            const err: AxiosError = error as AxiosError;

            if (err.response) {
                console.log(err);
            } else if (err.request) {
                mainStore.addAlert({
                    message: 'Er kon geen verbinding gemaakt worden met de server.<br />Probeer later opnieuw.',
                    type: 'error',
                });
            } else {
                // Something happened in setting up the request and triggered an Error
                console.log('Error', err.message);
            }

            state.value = 'error';
        } finally {
            setTimeout(() => {
                if (state.value === 'success') {
                    emit(UPDATE_MODEL_VALUE);
                }
                emit('cancel:report');
            }, 2000);
        }
    };

    const cancel = () => {
        state.value = 'idle';
        emit('cancel:report');
        newReport.$reset();
    };
</script>

<style scoped lang="scss">
    @import '@/assets/css/mixins/breakpoints';

    #new-report {
        display: grid;
        grid-template-areas: 'header' 'body' 'actions';
        grid-template-rows: auto 1fr auto;
    }

    .container {
        display: grid;
        grid-template-areas: 'subject' 'report' 'patient';
        grid-template-rows: repeat(3, auto);
        padding: 2.5rem 0;
        position: relative;
        row-gap: 5rem;
    }

    .row {
        align-items: center;
        display: flex;
        flex-direction: column;
        padding: 0 2.5rem;
        width: 100%;

        @include breakpoint(xx-small) {
            padding: 0;
        }

        h2 {
            color: hsl(var(--theme-color));
            margin-bottom: 1rem;
        }
    }

    .header {
        align-items: center;
        background-color: white;
        border-bottom: 1px solid hsl(var(--grey-500));
        display: grid;
        grid-area: header;
        grid-template: repeat(3, auto) / repeat(3, auto);
        grid-template-areas:
            '. title .'
            '. indicator .'
            '. progress .';
        padding: 0 0 2rem;
        position: sticky;
        top: 0;
        width: 100%;

        @include breakpoint(xx-small) {
            grid-template: auto / auto;
            grid-template-areas:
                'title'
                'indicator'
                'progress';
        }

        h1 {
            color: black;
            grid-area: title;
            justify-self: center;
        }

        .scroll-indicator {
            display: flex;
            grid-area: indicator;
            justify-content: space-between;
            margin-top: 1.5rem;
            width: 100%;

            @include breakpoint(xx-small) {
                margin-top: 0;
            }

            span {
                @include font-base;

                text-transform: capitalize;
                transition-duration: 300ms;
                transition-property: color;
                will-change: color;

                @media only screen and (max-width: 320px) {
                    @include font-m;

                    margin-top: 0;
                }

                &.si-active {
                    color: hsl(var(--theme-color));
                    font-weight: bold;
                }
            }
        }

        .progress {
            grid-area: progress;
            width: 100%;

            progress {
                appearance: none;
                height: 4px;
                width: 100%;

                &[value]::-webkit-progress-bar {
                    background-color: hsl(var(--grey-400));
                    border-radius: 500px;
                }

                &::-webkit-progress-value {
                    background-color: hsl(var(--theme-color));
                    border-radius: 500px;
                    transition: width 300ms;
                }
            }
        }
    }

    .subject {
        grid-area: subject;

        .report-subject__wrapper {
            display: grid;
            grid-gap: 1rem;
            grid-template-columns: repeat(auto-fit, minmax(50px, calc((100% - 5rem) / 2)));
            justify-content: center;
            width: clamp(250px, 95%, 600px);

            @include breakpoint(small) {
                grid-template-columns: repeat(auto-fit, minmax(50px, calc((100% - 5rem) / 4)));
                width: clamp(295px, 100%, 600px);
            }

            .report-subject {
                border: 2px solid hsl(var(--grey-400));
                border-radius: 0.875rem;
                box-shadow: 0 2px 0 hsl(var(--grey-400));
                cursor: pointer;
                display: grid;
                grid-template-rows: 60px auto;
                padding: 1rem;
                place-items: center;
                transition-duration: 300ms;
                transition-property: background-color, border-color;
                will-change: background-color, border-color;

                &--active {
                    background-color: hsl(var(--purple-300));
                    border-color: hsl(var(--theme-color));
                    border-width: 2px;

                    .report-subject-icon :deep(path),
                    .report-subject-icon :deep(rect),
                    .report-subject-icon :deep(line) {
                        stroke: hsl(var(--theme-color));
                    }

                    .report-subject-icon :deep(.icon-fill) {
                        fill: hsl(var(--theme-color));
                    }
                }

                &:hover,
                &:focus {
                    background-color: hsl(var(--purple-300));
                    border-color: hsl(var(--theme-color));
                    border-width: 2px;
                }

                &__title {
                    @include font-base-bold;

                    color: hsl(var(--theme-color));
                    justify-self: center;
                    line-height: 1;
                    margin-top: 0.5rem;

                    @include breakpoint(small) {
                        @include font-m-bold;

                        margin-top: 0.5rem;
                    }
                }
            }
        }
    }

    label {
        color: hsl(var(--grey-800));
        font-weight: 800;
    }

    .patient {
        grid-area: patient;

        .input-wrapper {
            display: grid;
            width: clamp(200px, 100%, 250px);
        }
    }

    input[type='text'],
    input[type='date'] {
        background: hsl(var(--grey-200));
        border: none;
        border-bottom: 1px solid hsl(var(--grey-600));
        border-radius: 5px 5px 0 0;
        color: hsl(var(--grey-700));
        display: block;
        font-size: 16px;
        font-weight: 400;
        line-height: 1.5;
        margin-top: 0.625rem;
        outline: 0;
        padding: 0.75rem 1rem;
        width: 100%;

        &:first-of-type {
            margin-bottom: 0.75rem;
        }

        &:focus {
            border-color: hsl(var(--purple-500));
        }

        &:not(:placeholder-shown, :focus):invalid {
            border-color: hsl(var(--red-800));
        }
    }

    textarea {
        background: transparent;
        border: 1px solid hsl(var(--grey-500));
        border-radius: 5px;
        color: hsl(var(--grey-700));
        height: 15rem;
        margin-top: 1.5rem;
        max-width: 650px;
        padding: 1.5rem 2.25rem;
        resize: none;
        transition-duration: 300ms;
        transition-property: border-color;
        width: calc(100% - 2rem);

        &:hover {
            border-color: hsl(var(--theme-color));
        }

        &:focus {
            outline-color: hsl(var(--theme-color));
        }
    }

    input {
        background-color: hsl(var(--grey-200));
        border: none;
        border-bottom: 1px solid hsl(var(--grey-600));
        border-radius: 5px 5px 0 0;
        color: hsl(var(--grey-700));
        font-size: 16px;
        font-weight: 400;
        line-height: 1.5;
        margin-top: 0.625rem;
        outline: 0;
        padding: 0.75rem 1rem;
        transition-duration: 500ms;
        transition-property: background-color, color;
        width: 100%;

        &:focus {
            background-color: hsl(var(--purple-500) / 10%);
            border-color: hsl(var(--purple-500));
            color: hsl(var(--purple-700));
        }

        &.dirty:not(:focus):invalid {
            border-color: hsl(var(--red-800));
        }
    }

    footer {
        border-top: 1px solid hsl(var(--grey-500));
        display: grid;
        grid-area: actions;
        grid-template-areas: 'divider' 'buttons';
        grid-template-columns: 1fr;

        .actions {
            padding: 0;
            padding-top: var(--v-card-padding);
        }
    }

    .results {
        margin-top: 2rem;
        padding: 1rem;
        width: clamp(250px, 90%, 600px);

        strong {
            color: hsl(var(--theme-color));
        }

        .result {
            padding: 0.5rem 0;

            .name {
                margin-left: 1rem;
                vertical-align: middle;
            }

            &.result-active {
                color: hsl(var(--theme-color));
            }
        }
    }
</style>
