import { buildAbilityFor } from '@/config/ability';
import { ABILITY_TOKEN } from '@casl/vue';
import type { User } from '@nietpluis/common/interfaces/person';
import type { AxiosError } from 'axios';
import axios from 'axios';
import { wrapper } from 'axios-cookiejar-support';
import { defineStore } from 'pinia';
import { CookieJar } from 'tough-cookie';
import { computed, inject, ref } from 'vue';
import { useMainStore } from './main.store';

wrapper(axios);

const jar = new CookieJar();

axios.defaults.withCredentials = true;
axios.defaults.baseURL = import.meta.env.VITE_API_BASE_URL as string;

axios.interceptors.response.use(
    (response) => response,
    async (error) => {
        const originalConfig = error.config;
        if (originalConfig.url === '/auth/login') {
            if (error.code === 'ERR_NETWORK') {
                handleCORS();
                return;
            }

            handleError(error.response);
            return;
        }

        if (error.response?.status === 401 && !originalConfig._retry) {
            if (originalConfig.url === '/auth/refresh') return;

            originalConfig._retry = true;
            try {
                const currentUserStore = useCurrentUserStore();

                await currentUserStore.refreshToken();
                return axios(originalConfig);
            } catch (_error) {
                console.error('Refresh token failed');
                console.error(_error);
                return Promise.reject(_error);
            }
        }
        return Promise.reject(error);
    },
);

const handleCORS = () => {
    const mainStore = useMainStore();
    mainStore.addAlert({
        message: `Door een technische storing kunt u op dit moment niet inloggen.
                                        <br />Probeer later opnieuw a.u.b.`,
        type: 'error',
    });
};

const handleError = (response: { status: number; data: { reason: string } }) => {
    const UNAUTHORIZED = 401;
    const INTERNAL_SERVER_ERROR = 500;

    const mainStore = useMainStore();

    if (response.status === UNAUTHORIZED && response.data.reason === 'Wrong credentials provided') {
        mainStore.addAlert({ message: 'Gebruikersnaam en/of wachtwoord onjuist', type: 'error' });
    }

    if (response.status === UNAUTHORIZED && response.data.reason === 'Account inactive') {
        mainStore.addAlert({ message: 'Gebruikersnaam en/of wachtwoord onjuist', type: 'error' });
    }

    if (response.status === UNAUTHORIZED && response.data.reason === 'Account not activated') {
        mainStore.addAlert({
            message: `U heeft uw account nog niet geactiveerd.
                                  <br />
                                  Klik <a href='/auth/activatie-link'>hier</a> om de activatie email opnieuw te sturen.`,
            persistent: true,
            type: 'error',
        });
    }

    if (response.status === INTERNAL_SERVER_ERROR) {
        mainStore.addAlert({
            message: `Door een technische storing kunt u op dit moment niet inloggen.
                                  <br />Probeer later opnieuw a.u.b.`,
            type: 'error',
        });
    }
};

export interface ProfileResponse {
    user: User;
    hash: string;
}

interface LoginData {
    username: string;
    password: string;
}

export const useCurrentUserStore = defineStore('currentUser', () => {
    const user = ref<User | null>(null);
    const hash = ref<string | null>(null);
    const $ability = inject(ABILITY_TOKEN);

    const accessToken = computed(() => {
        return sessionStorage.getItem('accessToken');
    });

    const isAuthenticated = computed((): boolean => {
        return accessToken.value != undefined;
    });

    const ping = async (): Promise<void> => {
        await axios.head(`/user/me`);

        if (!hash.value) {
            await me();
        }
    };

    const me = async (): Promise<void> => {
        const { data }: { data: ProfileResponse } = await axios.get(`/user/me`, { jar, withCredentials: true });

        hash.value = data.hash;

        if (data.user) {
            user.value = data.user;

            const appAbility = buildAbilityFor(data.user);
            $ability?.update(appAbility.rules);
        }
    };

    const login = async (loginData: LoginData): Promise<User> => {
        const { data } = await axios.post(
            `/auth/login`,
            {
                ...loginData,
            },
            { jar, withCredentials: true },
        );

        return data;
    };

    const logout = async (): Promise<void> => {
        const { data } = await axios.post('/auth/logout', { jar, withCredentials: true });
        user.value = null;
        hash.value = null;
        return data;
    };

    const refreshToken = async (): Promise<void> => {
        try {
            await axios.get('/auth/refresh', { jar, withCredentials: true });
        } catch (err) {
            const error = err as AxiosError;
            // The user is not logged in anymore
            if (error.response?.status === 401) {
                await logout();
                return;
            }
        }
    };

    const generateSalt = (saltRounds: number = 10): string => {
        const saltBuffer = new Uint8Array(saltRounds);
        crypto.getRandomValues(saltBuffer);

        return Array.from(saltBuffer)
            .map((byte) => byte.toString(16).padStart(2, '0'))
            .join('');
    };

    const hashPassword = async (password: string): Promise<string> => {
        const salt = import.meta.env.VITE_PW_SALT;

        // Combine the salt and password
        const saltedPassword = `${salt}${password}`;

        // Convert the combined salt and password to an ArrayBuffer
        const encoder = new TextEncoder();
        const data = encoder.encode(saltedPassword);

        // Hash the data using the bcrypt algorithm
        const hashBuffer = await crypto.subtle.digest('SHA-256', data);

        // Convert the hash to a string representation
        const hashArray = Array.from(new Uint8Array(hashBuffer));
        const hashHex = hashArray.map((byte) => byte.toString(16).padStart(2, '0')).join('');

        return hashHex;
    };

    return { user, hash, accessToken, isAuthenticated, ping, me, login, logout, refreshToken, hashPassword };
});
