<template>
    <div v-if="reports.patients.length" class="report-list">
        <div class="list-header">
            <span class="label">naam</span>
            <span class="label">onderwerp</span>
        </div>
        <div
            v-for="patient in reports.patients as Patient[]"
            :key="patient.id"
            class="report-wrapper"
            :style="`--max-height: ${(patient.reports?.length + 1) * maxHeightPerLine}px`"
            :class="{
                active: isActivePatient(patient),
            }"
            @click="setActive">
            <router-link :to="{ name: 'MeldingenPatient', params: { id: patient.id } }" class="patient">
                <svg-icon
                    id="read-status"
                    name="unread"
                    :class="{
                        'read-status--done': patient.hasAllReportsAssigned,
                        'read-status--default': !patient.hasAllReportsAssigned,
                    }" />
                <span class="patient__name">{{ useFullname(patient, { initials: true }) }}</span>
                <span class="patient__dob">{{ formatDate(patient.dob) }}</span>
            </router-link>
            <router-link
                v-for="report in patient.reports"
                v-slot="{ isActive }"
                :key="report.id"
                :to="{ name: 'Melding', params: { id: patient.id, reportId: report.id } }"
                class="report">
                <span class="report__date">{{ formatDate(report.createdAt) }}</span>
                <span class="report__subject">{{ report.subject }}</span>
                <svg-icon id="chevron" name="chevron" small :class="{ 'is-active': isActive && isActiveReport(report) }" />
            </router-link>
        </div>
    </div>
    <div v-else>
        <div class="centered">Er zijn geen meldingen gevonden</div>
    </div>
</template>

<script setup lang="ts">
    import SvgIcon from '@/components/svgIcon.vue';
    import { formatDate } from '@/composables/use-time-format';
    import useFullname from '@/composables/useFullname';
    import { useFilterStore } from '@/stores/filter.store';
    import { useReportStore } from '@/stores/report.store';
    import type { Patient } from '@nietpluis/common/interfaces/person';
    import type { Report } from '@nietpluis/common/interfaces/report';
    import { computed, onMounted, ref, type Ref } from 'vue';
    import { useRoute } from 'vue-router';

    const route = useRoute();
    const activeElement: Ref<HTMLElement | null> = ref(null);
    const reports = useReportStore();
    const filters = useFilterStore();

    const getFontSize = () => parseFloat(getComputedStyle(document.documentElement).fontSize);
    const fontSize = ref(getFontSize());

    onMounted(async () => {
        await reports.fetchReports(filters.report);
        activeElement.value = document.querySelector('.report-wrapper.active') as HTMLElement;
        fontSize.value = getFontSize();

        window.onresize = function () {
            fontSize.value = getFontSize();
        };
    });

    const maxHeightPerLine = computed(() => 3.5 * fontSize.value);
    const wrapperMaxHeight = computed(() => `${maxHeightPerLine.value}px`);

    const isActivePatient = (patient: Patient): boolean => route.params.id === patient.id;
    const isActiveReport = (report: Report): boolean => route.params.reportId.toString() === report.id.toString();

    const setActive = (event: Event): void => {
        const target = event.target as HTMLElement;

        if (target.classList.contains('report')) {
            return;
        }

        activeElement.value?.classList.remove('active');
        target.closest('.report-wrapper')?.classList.add('active');

        activeElement.value = target.closest('.report-wrapper') as HTMLElement;
        const firstReport: HTMLElement | null = activeElement.value?.querySelector('.report');
        firstReport?.click();
    };
</script>

<style scoped lang="scss">
    .centered {
        height: 30px;
        margin: 60px 0;
    }

    .report-list {
        padding: 2rem 3.5rem;
    }

    .report--read #read-status:deep(path) {
        fill: hsl(var(--blue-600));
    }

    a:not(.router-link-active):hover #chevron:deep(path),
    a:not(.router-link-active):focus #chevron:deep(path) {
        fill: hsl(var(--theme-color));
    }

    .report-wrapper {
        max-height: v-bind('wrapperMaxHeight');
        overflow: hidden;
        transition-duration: 300ms;
        transition-property: color, max-height;
        transition-timing-function: cubic-bezier(0.5, 0, 0.5, 1);

        .patient,
        .report {
            align-items: center;
            border-radius: 5px;
            column-gap: 0.5rem;
            cursor: pointer;
            display: grid;
            grid-template-rows: 3.5rem;
            position: relative;
            user-select: none;
        }

        .patient {
            grid-template-areas: 'icon name cause chevron';
            grid-template-columns: 3.5rem 1fr 6rem 1.5rem;
            transition-duration: 300ms;
            transition-property: color, background-color;
            transition-timing-function: cubic-bezier(0.5, 0, 0.5, 1);

            #read-status {
                grid-area: icon;
                justify-self: center;

                &.read-status--default:deep(path) {
                    fill: hsl(var(--grey-500));
                }

                &.read-status--done:deep(path) {
                    fill: hsl(var(--blue-600));
                }
            }

            .patient__name {
                grid-area: name;
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
            }

            .patient__dob {
                grid-area: cause;
            }
        }

        :hover,
        :focus {
            color: hsl(var(--theme-color));
        }

        &.active {
            color: hsl(var(--theme-color));
            font-weight: 700;
            max-height: var(--max-height);

            .patient {
                background-color: hsl(var(--purple-400) / 10%);
                color: hsl(var(--theme-color));
            }
        }

        .report {
            border-left: 1px solid hsl(var(--purple-200));
            border-radius: 0;
            font-weight: 400;
            grid-template-areas: 'date subject chevron';
            grid-template-columns: 1fr 6rem 1.5rem;
            margin-left: 2rem;
            padding-left: 2rem;

            .report__date {
                grid-area: date;
                pointer-events: none;
            }

            .report__subject {
                grid-area: subject;
                pointer-events: none;
            }

            #chevron {
                grid-area: chevron;
                justify-self: center;
                pointer-events: none;
            }

            &::before {
                background-color: hsl(var(--purple-200));
                border-radius: 50%;
                content: '';
                display: block;
                height: 5px;
                left: -2.5px;
                position: absolute;
                top: calc(50% - 2px);
                width: 5px;
            }

            &:last-child::before {
                border: 2px solid white;
                box-shadow: 0 0 0 1pt hsl(var(--purple-200));
                left: -5px;
                top: calc(50% - 3px);
            }

            &:last-child::after {
                background-color: white;
                content: '';
                height: 60%;
                left: -1px;
                position: absolute;
                top: calc(50% + 7px);
                width: 1px;
            }
        }
    }

    .list-header {
        column-gap: 0.5rem;
        display: grid;
        grid-template-areas: '. name subject .';
        grid-template-columns: 3.5rem 1fr 96px 1.5rem;
        margin-bottom: 1rem;

        .label:first-child {
            grid-area: name;
        }

        .label:last-child {
            grid-area: subject;
        }
    }
</style>
