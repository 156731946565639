<template>
    <div class="v-card-title type-label"><slot /></div>
</template>

<style lang="scss" scoped>
    .v-card-title {
        align-items: center;
        display: flex;
        flex-wrap: wrap;
        font-weight: 600;
        line-height: 1.6em;
        margin-top: 4px;
        padding: var(--v-card-padding);
    }
</style>
