<template>
    <div class="main-container">
        <SubNav>
            <a href="#" :class="{ 'router-link-active': reportFilter === 'open' }" @click="setFilter('open')"> Open meldingen </a>
            <a href="#" :class="{ 'router-link-active': reportFilter === 'past-day' }" @click="setFilter('past-day')"> afgelopen 24 uur </a>
        </SubNav>
        <aside>
            <transition name="fade-quick" mode="out-in">
                <!-- Change to Loader component -->
                <div v-if="loadingList" class="loader">Loading...</div>
                <ReportList v-else context="patient" />
            </transition>
            <div class="create-button">
                <v-dialog v-if="canCreateReport" v-model="newReportDialogActive" @keydown.esc="newReportDialogActive = false">
                    <template #activator="{ on }">
                        <button class="button button--secondary" @click="on">Nieuwe melding</button>
                    </template>

                    <NewReport @cancel:report="newReportDialogActive = false" />
                </v-dialog>
            </div>
            <footer>
                <PrivacyStatement />
                <Version />
            </footer>
        </aside>
        <main>
            <router-view v-slot="{ Component }" :key="`a${route.params.id}`" class="sticky">
                <transition name="fade-quick" mode="out-in">
                    <component :is="Component" override-filter="patient" />
                </transition>
            </router-view>
            <router-view v-slot="{ Component }" :key="`a${route.params.reportId}`" name="report">
                <transition name="fade-quick" mode="out-in">
                    <component :is="Component" />
                </transition>
            </router-view>
        </main>
    </div>
</template>

<script setup lang="ts">
    import { useAppAbility } from '@/composables/useAppAbility';
    import { useFilterStore, type ReportFilter } from '@/stores/filter.store';
    import { useReportStore } from '@/stores/report.store';
    import { computed, onMounted, ref } from 'vue';
    import { useRoute, useRouter } from 'vue-router';

    const { can } = useAppAbility();
    const route = useRoute();
    const router = useRouter();
    const reports = useReportStore();
    const filters = useFilterStore();

    const loadingList = ref(false);
    const newReportDialogActive = ref(false);

    onMounted(() => initReports());

    const reportFilter = computed(() => filters.report);

    const setFilter = (filter: string): void => {
        loadingList.value = true;
        filters.setReportFilter(filter as ReportFilter);

        initReports();
    };

    const initReports = async (): Promise<void> => {
        await reports.fetchReports(filters.report);

        if (reports.patients.length && (!route.params.id || !route.params.reportId)) {
            router.push({
                name: 'Melding',
                params: {
                    id: reports.patients[0].id,
                    reportId: reports.patients[0].reports[0].id,
                },
            });
        }

        loadingList.value = false;
    };

    const canCreateReport = computed((): boolean => can('create', 'Report'));
</script>

<style scoped lang="scss">
    .main-container {
        display: grid;
        grid-gap: 0;
        grid-template: auto 1fr / 31rem 1fr;
        grid-template-areas: 'subnav subnav' 'side main';
        row-gap: 0;
    }

    .sub-nav {
        grid-area: subnav;
    }

    aside {
        border-right: 5px solid hsl(var(--grey-500) / 30%);
        display: grid;
        grid-area: side;
        grid-template-rows: auto auto 1fr;

        footer {
            align-self: flex-end;
            display: grid;
            margin-top: 0;
            padding: 1rem;
            place-items: center;
        }
    }

    main {
        max-height: calc(100vh - var(--navbar-height) - var(--subnav-height) - 1rem);
        overflow: auto;

        .sticky {
            background-color: white;
            position: sticky;
            top: -1.625rem;
            z-index: 10;
        }
    }
</style>
