import type { ResultResponse } from '@/interfaces/Response';
import type { Patient } from '@nietpluis/common/interfaces/person';
import type { CreateReportDTO, Report } from '@nietpluis/common/interfaces/report';
import axios from 'axios';
import { defineStore } from 'pinia';

interface NewReportState {
    data: {
        patient: Patient | undefined;
        subject: string;
        description: string;
    };
    search: {
        lastName: string;
        dob: string;
    };
    progress: {
        subject: number;
        description: number;
        patient: number;
    };
    results: Patient[];
}

export const useNewReportStore = defineStore('new-report', {
    state: (): NewReportState => ({
        data: {
            patient: undefined,
            subject: '',
            description: '',
        },
        search: {
            lastName: '',
            dob: '',
        },
        progress: {
            subject: 0,
            description: 0,
            patient: 0,
        },
        results: [] as Patient[],
    }),
    getters: {},
    actions: {
        async create(createReport: CreateReportDTO): Promise<Report> {
            const { data }: { data: Report } = await axios.post(`${import.meta.env.VITE_API_BASE_URL}/report`, createReport);

            return data;
        },

        async getPatientByLastNameAndDOB(query: { lastName: string; dob: string }): Promise<void> {
            const params = {
                lastName: query.lastName,
                dob: query.dob,
            };

            const { data }: { data: ResultResponse<Patient> } = await axios.get('/person/find', { params });

            this.results = data.results;
        },
    },
});
