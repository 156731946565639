<template>
    <div class="v-dialog">
        <slot name="activator" v-bind="{ on: () => setInternalActive(true) }"></slot>

        <teleport to="#dialog-outlet">
            <transition-dialog @after-leave="leave">
                <div v-if="internalActive" class="container" :class="[className, placement]">
                    <v-overlay active absolute @click="emitToggle" />
                    <slot></slot>
                </div>
            </transition-dialog>
        </teleport>
    </div>
</template>

<script setup lang="ts">
    import { TransitionDialog } from '@/components/transition/dialog';
    import { VOverlay } from '@/components/v-overlay';
    import { useDialogRouteLeave } from '@/composables/use-dialog-route';
    import { UPDATE_MODEL_VALUE } from '@/constants/events';
    import type { DialogPosition } from '@/interfaces/Alert';
    import { computed, ref } from 'vue';

    const emit = defineEmits([UPDATE_MODEL_VALUE]);
    const props = withDefaults(
        defineProps<{
            persistent?: boolean;
            placement?: DialogPosition;
            modelValue: boolean;
        }>(),
        {
            persistent: false,
            placement: 'middle-center',
            modelValue: undefined,
        },
    );

    const localActive = ref(false);
    const className = ref<string | null>(null);
    const leave = useDialogRouteLeave() ?? (() => {});

    const internalActive = computed((): boolean => {
        return props.modelValue !== undefined ? props.modelValue : localActive.value;
    });

    const setInternalActive = (newActive: boolean) => {
        localActive.value = newActive;
        emit(UPDATE_MODEL_VALUE, newActive);
    };

    const emitToggle = (): void => {
        if (props.persistent === false) {
            emit(UPDATE_MODEL_VALUE, false);
        } else {
            nudge();
        }
    };

    const nudge = (): void => {
        className.value = 'nudge';

        setTimeout(() => {
            className.value = null;
        }, 200);
    };
</script>

<style lang="scss" scoped>
    @import '@/assets/css/mixins/breakpoints';

    .v-dialog {
        --v-dialog-z-index: 100;

        display: contents;
        position: relative;
    }

    .container {
        display: flex;
        height: 100%;
        left: 0;
        position: fixed;
        top: 0;
        width: 100%;
        z-index: 500;

        > :slotted(:not(form)),
        > :slotted(form) > * {
            border: 3px solid hsl(var(--purple-500));
            box-shadow: 0.5rem 0.5rem 0 hsl(var(--purple-500));
            z-index: 2;
        }

        > :slotted(form) {
            z-index: 2;
        }

        &.middle-center {
            align-items: center;
            justify-content: center;

            &.nudge > :slotted(*:not(:first-child)) {
                animation: nudge 200ms;
            }
        }

        &.top-center {
            align-items: flex-start;
            justify-content: center;

            &.nudge > :slotted(*:not(:first-child)) {
                animation: nudge 200ms;
                transform-origin: top;
            }
        }

        &.right {
            align-items: center;
            justify-content: flex-end;

            &.nudge > :slotted(*:not(:first-child)) {
                animation: nudge 200ms;
                transform-origin: right;
            }
        }

        :slotted(.v-card) {
            --v-card-min-width: calc(100vw - 40px);
            --v-card-padding: 28px;
            --v-card-background-color: white;
            --v-card-margin: 2rem;

            @include breakpoint(xx-small) {
                --v-card-padding: 1rem;
            }

            margin: var(--v-card-margin);
            z-index: 9;

            .v-card-title {
                /* padding-bottom: 8px; */
            }

            .v-card-actions {
                flex-direction: inherit;
                flex-wrap: nowrap;

                button {
                    width: 50%;
                }
            }

            @include breakpoint(small) {
                --v-card-min-width: 540px;

                .v-card-actions {
                    flex-direction: inherit;
                    flex-wrap: nowrap;

                    button {
                        width: auto;
                    }
                }
            }
        }

        :slotted(.v-sheet) {
            --v-sheet-padding: 24px;
            --v-sheet-max-width: 560px;
        }

        .v-overlay {
            --v-overlay-z-index: 1;
        }
    }

    @keyframes nudge {
        0% {
            transform: scale(1);
        }

        50% {
            transform: scale(1.02);
        }

        100% {
            transform: scale(1);
        }
    }

    @keyframes shake {
        0% {
            transform: scaleX(1);
        }

        50% {
            transform: scaleX(0.98);
        }

        100% {
            transform: scaleX(1);
        }
    }
</style>
