<template>
    <section class="address">
        <span class="street gc-7 gr-2">
            <label for="street">Straat</label>
            <input id="street" v-model="address.street" type="text" v-format.capitalize.non-digit @input="updateModelValue" required />
        </span>
        <span class="houseNumber gc-3 gr-2">
            <label for="houseNumber">Huisnummer</label>
            <input id="houseNumber" v-model="address.houseNumber" type="text" @input="updateModelValue" required />
        </span>
        <span class="houseNumberAddition gc-2 gr-2">
            <label for="houseNumberAddition">Toevoeging</label>
            <input id="houseNumberAddition" v-model="address.houseNumberAddition" type="text" @input="updateModelValue" />
        </span>
        <span class="zipcode gc-2 gr-3">
            <label for="zipcode">Postcode</label>
            <input id="zipcode" v-model="address.zipcode" type="text" maxlength="6" @input="updateModelValue" required />
        </span>
        <span class="city gc-5 gr-3">
            <label for="city">Stad</label>
            <input id="city" v-model="address.city" type="text" v-format.capitalize.non-digit @input="updateModelValue" required />
        </span>
    </section>
</template>

<script setup lang="ts">
    import { UPDATE_MODEL_VALUE } from '@/constants/events';
    import { Address } from '@nietpluis/common/interfaces/common';
    import { reactive } from 'vue';

    const emit = defineEmits([UPDATE_MODEL_VALUE]);

    const address = reactive(new Address());

    const updateModelValue = (): void => {
        emit(UPDATE_MODEL_VALUE, address);
    };
</script>

<style scoped lang="scss"></style>
