import type { ResultResponse } from '@/interfaces/Response';
import type { ActivateData, CreateGpDTO, Details, GP } from '@nietpluis/common/interfaces/person';
import axios, { type AxiosResponse } from 'axios';
import { defineStore } from 'pinia';

export const useGPStore = defineStore('gp', {
    state: () => ({
        GPs: [] as GP[],
        GP: undefined as GP | undefined,
        results: [] as GP[],
    }),
    getters: {
        getGPs: (state) => state.GPs,
    },
    actions: {
        async fetchGPs(): Promise<void> {
            const { data }: { data: ResultResponse<GP> } = await axios.get('/person/?type=gp');

            this.GPs = data.results;
        },

        async fetchGPById(id: string, force = false): Promise<void> {
            if (!id) {
                throw Error('No GP id given');
            }

            const foundGP = this.GPs.find((GP) => GP.id === id);

            if (!foundGP || force) {
                const { data }: { data: GP } = await axios.get(`/person/${id}`);
                this.GP = data;
            } else {
                this.GP = foundGP;
            }
        },

        async findGP(details: Partial<Details>): Promise<void> {
            const params = { ...details, type: 'gp' };
            const { data }: { data: ResultResponse<GP> } = await axios.get(`/person/find`, { params });

            this.results = data.results;
        },

        async create(gp: CreateGpDTO): Promise<AxiosResponse> {
            return axios.post('/person', gp);
        },

        async activate(activateData: ActivateData): Promise<{ accessToken: string }> {
            const { data } = await axios.post('/person/activate', {
                ...activateData,
            });

            return data;
        },

        async checkToken(token: string): Promise<AxiosResponse> {
            console.log(token);
            return axios.get(`/person/token/${token}`);
        },

        async checkIfActive(id: string): Promise<AxiosResponse> {
            return axios.get(`/person/active/${id}`);
        },

        async resendActivation(options: { id: string; email: string }): Promise<{ accessToken: string }> {
            const { data } = await axios.post('/person/resend-activation', {
                id: options.id,
                email: options.email,
            });

            return data;
        },
    },
});
