import type { FullNameOptions, Gender, People } from '@nietpluis/common/interfaces/person';

const salutation = (gender?: Gender): string => {
    if (gender === 'male') {
        return 'Dhr.';
    }
    if (gender === 'female') {
        return 'Mevr.';
    }
    return '';
};
export default function useFullname(person: People, options?: FullNameOptions): string {
    const salute = salutation(person.details.gender);
    let fullName = `${person.details.firstName} ${person.details.lastName}`;

    if (options?.initials && person.details.initials) {
        fullName = `${person.details.initials} ${person.details.lastName}`;
    }

    if (options?.salutation) {
        return `${salute} ${fullName}`;
    }

    return fullName;
}
