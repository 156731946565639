<template>
    <svg class="report-subject-icon" width="65" height="66" viewBox="0 0 65 66" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M32.7471 0.778241C50.5643 0.778241 64.8987 15.3206 64.8699 33.3474C64.8411 50.7118 50.2765 65.0526 32.6896 65.1101C15.1314 65.1677 -0.0376765 50.6254 0.566784 31.6195C1.08489 15.1766 14.8724 0.749445 32.7471 0.778241ZM32.6896 2.96679C16.2252 2.9092 2.95584 16.1557 2.72557 31.9075C2.46652 49.5599 15.7071 62.9504 32.9486 62.9216C49.1251 62.8928 62.6536 49.4159 62.6823 33.261C62.7399 16.4437 49.3554 2.938 32.6896 2.96679Z"
            fill="#7333A5" />
        <path
            d="M44.0014 41.2952C44.1165 43.9157 44.2605 46.5363 44.3756 49.1568C44.4907 51.6333 42.994 53.3035 40.6625 53.3035C38.4749 53.3035 36.9781 51.5469 37.0933 49.1856C37.3523 43.9733 37.5826 38.7899 37.8417 33.5777C37.8992 32.7138 37.7553 32.1091 36.9494 31.5332C35.1935 30.2661 34.6754 28.3079 34.8769 26.3209C35.1072 24.1036 35.539 21.8862 36.2586 19.7841C36.9494 17.8259 38.6764 16.8468 40.7776 16.8756C42.9076 16.8756 44.462 17.9699 45.2391 19.8993C46.3041 22.491 47.0237 25.2267 46.3905 27.9912C46.1026 29.2582 45.3255 30.5253 44.4332 31.5044C43.8287 32.1667 43.5409 32.7138 43.5984 33.5777C43.7999 36.1406 43.8863 38.7323 44.0014 41.2952C44.0302 41.2952 44.0014 41.2952 44.0014 41.2952ZM44.721 26.1482C44.2317 24.3052 43.8287 22.4622 43.253 20.648C42.9076 19.5537 41.929 19.0642 40.7776 19.0354C39.6263 19.0066 38.5613 19.5249 38.3022 20.5904C37.7553 22.6926 37.3236 24.8523 37.0933 27.0121C36.9494 28.3367 37.5538 29.6038 38.8491 30.2085C39.9717 30.7268 40.1444 31.5332 40.058 32.6562C39.8565 35.7951 39.7414 38.9627 39.5975 42.1016C39.4823 44.5493 39.3672 46.9682 39.3096 49.4159C39.2809 50.7118 40.0868 51.3165 41.2094 50.9997C42.1592 50.7406 42.2168 49.9919 42.188 49.1856C41.929 43.6566 41.6699 38.0988 41.4684 32.5698C41.4396 31.8787 41.7563 30.842 42.2456 30.5253C44.0014 29.5462 44.5483 28.0775 44.721 26.1482Z"
            fill="#7333A5" />
        <path
            d="M30.617 22.779C30.617 23.2974 30.5594 23.8445 30.617 24.3629C30.9336 26.8682 30.3291 28.9415 28.1991 30.4966C27.7674 30.8133 27.4795 31.6196 27.5083 32.1956C27.7386 37.8397 28.0264 43.4551 28.3143 49.0993C28.4294 51.4606 26.9326 53.246 24.8026 53.3036C22.5863 53.3612 20.9744 51.5758 21.0895 49.1568C21.3486 43.6279 21.6076 38.0701 21.9243 32.5411C21.9818 31.5908 21.8667 30.8421 21.0895 30.295C19.1322 28.9127 18.5278 27.0698 18.758 24.7084C18.9595 22.6639 18.7868 20.5905 18.8156 18.5459C18.8156 17.7684 18.8444 16.8757 19.9094 16.8757C20.9456 16.9045 21.0032 17.7396 21.0032 18.5459C21.0032 20.9936 20.9456 23.4414 21.032 25.8603C21.0607 26.5802 21.3774 27.5305 21.8955 27.9337C24.0543 29.5463 24.2845 31.6484 24.083 34.125C23.6801 39.1356 23.4786 44.1462 23.3059 49.1568C23.2771 49.7616 23.7376 50.6831 24.2558 50.971C25.1768 51.4894 26.1555 50.6255 26.1267 49.3872C26.0403 46.4788 25.8676 43.5703 25.7237 40.633C25.5798 37.8109 25.4935 34.9889 25.2632 32.1956C25.1481 30.8133 25.4935 29.7479 26.616 28.9415C28.1416 27.8185 28.6597 26.3786 28.487 24.4781C28.3143 22.4335 28.3718 20.3601 28.487 18.3156C28.5158 17.7972 29.0339 16.9909 29.4656 16.9045C30.4155 16.7029 30.617 17.538 30.617 18.3156C30.617 19.813 30.5882 21.3104 30.617 22.779C30.5882 22.779 30.5882 22.779 30.617 22.779Z"
            fill="#7333A5" />
        <path
            d="M54.1334 32.8291C54.047 38.5021 52.1761 43.3111 48.4918 47.4579C47.9449 48.0914 47.3116 49.0129 46.3905 48.149C45.4982 47.3139 46.3042 46.594 46.8799 45.9604C52.4064 39.6827 53.5289 31.5045 49.8158 24.1325C49.2401 22.9806 48.463 21.944 47.7146 20.9073C47.2541 20.2449 46.9662 19.5826 47.7146 19.0355C48.4918 18.4595 48.9811 19.0067 49.4704 19.6402C52.5503 23.499 54.0758 27.9913 54.1334 32.8291Z"
            fill="#7333A5" />
        <path
            d="M15.9946 19.8994C17.2035 19.8994 17.5489 20.7633 17.002 21.8C16.2249 23.211 15.275 24.5645 14.7281 26.0907C12.1951 33.2899 13.3465 39.8843 18.3261 45.7301C18.47 45.9029 18.7291 46.0756 18.7866 46.2772C18.9018 46.8532 19.2472 47.6595 18.9881 48.005C18.4124 48.7537 17.7216 48.293 17.2035 47.6883C11.2453 41.0362 9.74851 33.4915 12.7708 25.1116C13.3465 23.5566 14.2964 22.1456 15.1311 20.7057C15.3614 20.3314 15.7931 20.101 15.9946 19.8994Z"
            fill="#7333A5" />
        <path
            d="M32.2864 11.3179C34.2437 11.577 35.7693 11.7786 37.266 11.9802C38.0432 12.0954 38.7916 12.4697 38.4462 13.3624C38.3023 13.7656 37.3812 14.1399 36.9206 14.0536C33.3802 13.3912 29.8974 13.5352 26.4721 14.6871C25.7237 14.9463 24.8602 15.1478 24.5724 14.14C24.2557 13.1033 25.1193 12.8153 25.8676 12.6425C28.1128 12.1242 30.3867 11.6922 32.2864 11.3179Z"
            fill="#7333A5" />
        <path
            d="M24.1118 22.1742C24.1118 23.5277 24.1118 24.8523 24.1118 26.2058C24.1118 26.9545 23.8528 27.6744 23.018 27.4728C22.6151 27.3864 22.0682 26.6665 22.0682 26.2058C21.9818 23.5277 21.9818 20.8496 22.0682 18.1715C22.097 17.7395 22.7014 17.3076 23.0468 16.8756C23.3922 17.3076 23.9967 17.7107 24.0543 18.1427C24.1982 19.4673 24.1118 20.8208 24.1118 22.1742Z"
            fill="#7333A5" />
        <path
            d="M27.3355 22.3182C27.3355 23.6141 27.3355 24.8811 27.3355 26.177C27.3355 26.9257 27.134 27.6456 26.2705 27.444C25.8675 27.3576 25.3206 26.6665 25.2918 26.2346C25.2055 23.5853 25.2055 20.9072 25.2918 18.2579C25.3206 17.7971 25.9826 17.3364 26.328 16.9044C26.6734 17.3364 27.2491 17.7395 27.2779 18.2003C27.4218 19.5537 27.3355 20.936 27.3355 22.3182Z"
            fill="#7333A5" />
        <path
            d="M32.8619 52.1517C33.5815 52.1517 34.3299 52.0365 34.9919 52.1805C35.4237 52.2956 35.9418 52.7564 36.0857 53.1595C36.1721 53.3899 35.5676 54.1386 35.2222 54.1674C33.5815 54.2826 31.8833 54.2826 30.2426 54.1674C29.8972 54.1386 29.6093 53.4187 29.2927 53.0156C29.6669 52.7276 30.0123 52.2668 30.4441 52.1805C31.2212 52.0365 32.056 52.1517 32.8619 52.1517Z"
            fill="#7333A5" />
    </svg>
</template>
