<template>
    <div class="v-overlay" :class="{ active, absolute, 'has-click': clickable }" @click="onClick">
        <div class="overlay" />
        <div v-if="active !== undefined" class="content"><slot /></div>
    </div>
</template>

<script setup lang="ts">
    const emit = defineEmits(['click']);

    const props = withDefaults(
        defineProps<{
            active?: boolean;
            absolute?: boolean;
            clickable?: boolean;
        }>(),
        {
            active: false,
            absolute: false,
            clickable: true,
        },
    );

    const onClick = (event: MouseEvent): void => {
        emit('click', event);
    };
</script>

<style>
    body {
        --v-overlay-color: hsl(var(--theme-color) / 50%);
        --v-overlay-z-index: 600;
    }
</style>

<style lang="scss" scoped>
    .v-overlay {
        align-items: center;
        display: flex;
        height: 100%;
        justify-content: center;
        left: 0;
        pointer-events: none;
        position: fixed;
        top: 0;
        width: 100%;
        z-index: var(--v-overlay-z-index);

        &.has-click {
            cursor: pointer;
        }

        &.absolute {
            position: absolute;
        }

        .overlay {
            background-color: var(--v-overlay-color);
            height: 100%;
            left: 0;
            opacity: 0;
            position: absolute;
            top: 0;
            transition: opacity var(--slow) var(--transition);
            width: 100%;
        }

        &.active {
            pointer-events: auto;

            .overlay {
                opacity: 1;
            }
        }

        .content {
            position: relative;
        }
    }
</style>
