<template>
    <nav ref="subnav" class="sub-nav">
        <div class="page-links" @click="setActive">
            <slot></slot>
            <span ref="slider" id="slider"></span>
        </div>
    </nav>
</template>

<script setup lang="ts">
    import { useFilterStore, type FilterSlider } from '@/stores/filter.store';
    import { onBeforeUnmount, onMounted, ref, type Ref } from 'vue';

    const slider: Ref<HTMLElement | null> = ref(null);
    const subnav: Ref<HTMLElement | null> = ref(null);

    const filters = useFilterStore();

    onMounted(() => {
        initSlider();

        window.addEventListener('resize', initSlider);
    });

    onBeforeUnmount(() => {
        window.removeEventListener('resize', initSlider);
    });

    const setActive = (event: Event): void => {
        const { clientWidth: width, offsetLeft: left } = event.target as HTMLAnchorElement;
        setSlider(width, left);
    };

    const setSlider = (width: number, left: number) => {
        if (slider.value) {
            slider.value.style.width = `${width}px`;
            slider.value.style.left = `${left}px`;

            const sliderConfig: FilterSlider = {
                width,
                left,
            };

            filters.saveSliderConfig(sliderConfig);
        }
    };

    const initSlider = () => {
        if (slider.value) {
            const { clientWidth: width, offsetLeft: left } =
                (subnav.value?.querySelector('a.router-link-active') as HTMLElement) ??
                (subnav.value?.querySelector('a:first-child') as HTMLElement); // eslint-disable-line  max-len
            setSlider(width, left);
        }
    };
</script>

<style scoped lang="scss">
    nav {
        align-items: center;
        border-bottom: 1px solid hsl(var(--grey-500));
        display: flex;
        height: var(--subnav-height);
        justify-content: flex-start;
        left: 0;
        margin: 1rem 3.5rem 0;
        position: relative;
        top: 0;
    }

    .page-links {
        display: flex;
        position: relative;
        width: 40vw;

        ::v-slotted(a) {
            line-height: var(--subnav-height);
            padding: 0 1rem;
            -webkit-user-drag: none;
            user-select: none;
            white-space: nowrap;
        }

        ::v-slotted(a:hover),
        ::v-slotted(a:focus) {
            border-color: transparent;
        }

        ::v-slotted(a:first-of-type) {
            padding-left: 0;
        }
    }

    #slider {
        background-color: hsl(var(--theme-color));
        bottom: 0;
        height: 1px;
        left: 0;
        position: absolute;
        transition-duration: 500ms;
        transition-property: width, left;
        width: 0;
        z-index: 50;
    }
</style>
