import { ref, provide, inject, onMounted, type Ref, type InjectionKey } from 'vue';
import { onBeforeRouteLeave } from 'vue-router';

const dialogRouteSymbol: InjectionKey<() => void> = Symbol('dialog-route');

export function useDialogRoute(): Ref<boolean> {
    const isOpen = ref(false);

    let resolveGuard: () => void;
    const leaveGuard: Promise<void> = new Promise((resolve) => {
        resolveGuard = resolve;
    });

    onMounted(() => {
        isOpen.value = true;
    });

    onBeforeRouteLeave(() => {
        isOpen.value = false;

        return leaveGuard;
    });

    function leave() {
        resolveGuard();
    }

    provide(dialogRouteSymbol, leave);

    return isOpen;
}

export function useDialogRouteLeave(): (() => void) | undefined {
    return inject(dialogRouteSymbol, undefined);
}
