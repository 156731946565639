<template>
    <nav>
        <div class="page-links">
            <router-link v-if="canManageReports" v-slot="{ isActive }" :to="{ name: 'Meldingen' }">
                Meldingen
                <transition name="fade">
                    <Badge v-show="count" :color="getBadgeColor(isActive)" small>{{ count }}</Badge>
                </transition>
            </router-link>

            <router-link v-if="canManagePatients" :to="{ name: 'Patienten' }">Patiënten</router-link>
            <router-link v-if="canManageSome" :to="{ name: 'Contact', params: { filter } }">Rolodex</router-link>
        </div>
        <div class="logo">
            <div>
                <img src="@/assets/icons/logo.svg" alt="Niet Pluis" />
            </div>
        </div>
        <div class="profile">
            <VDialog v-model="signOutActive" @keyup.esc="signOutActive = false">
                <template #activator="{ on }">
                    <span class="profile__wrapper" tooltip="Klik om uit te loggen" tooltip-bottom @click="on">
                        <span class="profiel">
                            Welkom <span class="split"></span><span class="user">{{ currentUser.user?.details.firstName }}</span>
                        </span>
                        <img class="avatar" :src="url" alt="" width="40" height="40" />
                    </span>
                </template>

                <v-card>
                    <v-card-title>Uitloggen</v-card-title>

                    <v-card-subtitle>Weet u zeker dat u wilt uitloggen?</v-card-subtitle>
                    <v-card-actions>
                        <button type="button" secondary @click="signOutActive = false">Annuleren</button>
                        <button type="button" @click="logout">Uitloggen</button>
                    </v-card-actions>
                </v-card>
            </VDialog>
        </div>
    </nav>
</template>

<script setup lang="ts">
    import type { BadgeColor } from '@/components/Badge.vue';
    import { useAppAbility } from '@/composables/useAppAbility';
    import type { Subjects } from '@/config/ability';
    import { useCurrentUserStore } from '@/stores/currentUser.store';
    import { useFilterStore } from '@/stores/filter.store';
    import { useReportStore } from '@/stores/report.store';
    import { computed, ref } from 'vue';
    import { useRouter } from 'vue-router';

    const router = useRouter();
    const currentUser = useCurrentUserStore();
    const filters = useFilterStore();
    const reports = useReportStore();
    const signOutActive = ref(false);

    const { can } = useAppAbility();

    const count = computed((): number => reports.openReports);

    const url = computed((): string => `https://www.gravatar.com/avatar/${currentUser.hash}?s=80&&r=g&d=404&d=mm`);

    const logout = (): void => {
        currentUser.logout();
        router.push({ name: 'Login' });
    };
    const rolodexSubjects: Subjects[] = ['Practice', 'Homecare', 'GP', 'Patient', 'User'];
    const canManageReports = computed(() => can('manage', 'Report'));
    const canManagePatients = computed(() => can('read', 'Patient'));
    const canManageSome = computed(() => rolodexSubjects.every((subject) => can('manage', subject)));

    const getBadgeColor = (isActive: boolean): BadgeColor => (isActive ? 'purple' : undefined);

    const filter = computed((): string => filters.rolodex);
</script>

<style scoped lang="scss">
    @import '@/assets/css/mixins/breakpoints';

    nav {
        --avatar-size: 32px;
        --logo-width: 20vw;
        --navbar-padding: 1.5rem;

        align-items: center;
        border-bottom: 1px solid hsl(var(--grey-500));
        display: flex;
        font-style: normal;
        font-weight: 400;
        height: var(--navbar-height);
        justify-content: space-between;
        left: 0;
        line-height: 1.0625rem;
        position: relative;
        top: 0;
        width: 100vw;

        .split {
            display: block;
        }

        .user {
            color: hsl(var(--theme-color));
            font-weight: bold;
        }

        @include breakpoint(small) {
            --avatar-size: 32px;
            --logo-width: 33vw;
            --navbar-padding: 2.5rem;

            .split {
                display: none;
            }

            .user {
                color: unset;
                font-weight: 400;
            }
        }

        @include breakpoint(medium) {
            --avatar-size: 40px;
            --navbar-padding: 3.5rem;
        }
    }

    .page-links {
        height: 24px;
        line-height: 24px;
        padding-left: 2.5rem;
        white-space: nowrap;
        width: 33vw;

        a:not(:first-of-type) {
            margin-left: 2rem;
        }
    }

    .logo {
        color: hsl(var(--medium-grey));
        font-size: 1.25rem;
        font-weight: bold;
        height: var(--navbar-height);
        text-align: center;
        width: var(--logo-width);

        div {
            border-bottom: 2px solid white;
            height: var(--navbar-height);
            margin: 0 auto;
            margin-top: 1px;
            padding-top: 1rem;
            width: 7.25rem;

            &:deep() img {
                height: 3.75rem;
                width: 3.75rem;
            }
        }
    }

    .profile {
        padding-right: var(--navbar-padding);
        text-align: right;
        width: 33vw;
    }

    .profile__wrapper {
        cursor: pointer;
        display: inline-block;
        height: var(--avatar-size);
    }

    .profiel {
        display: inline-block;
        vertical-align: middle;
    }

    .avatar {
        border-radius: 3px;
        height: var(--avatar-size);
        margin-left: 0.75rem;
        vertical-align: middle;
        width: var(--avatar-size);
    }
</style>
