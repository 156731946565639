<template>
    <div class="autocomplete-wrapper" v-on-click-outside="hideSuggestions">
        <input
            ref="acInput"
            v-model="text"
            type="search"
            autocomplete="chrome-off"
            autocorrect="off"
            translate="no"
            :placeholder="placeholder"
            :spellcheck="spellcheck"
            :autocapitalize="autocapitalize"
            :class="styles"
            @input.stop="onInput"
            @keyup="updateSuggestions"
            @click="updateSuggestions"
            @keydown="handleKeystroke"
            @change="emit('change', $event)" />

        <teleport to="#suggestions-outlet">
            <ul
                v-if="suggestions.length > 0 && suggestionsVisible"
                class="suggestions"
                :style="`left: ${acInputBoundingRect?.left}px; top: ${
                    (acInputBoundingRect?.top ?? 0) + (acInputBoundingRect?.height ?? 0)
                }px;width: max-content;`">
                <li
                    v-for="(suggestion, index) in suggestions"
                    :key="`suggestion-${index}`"
                    :class="{ active: currentSuggestionIndex === index }"
                    @click="currentSuggestionIndex = index"
                    @click.prevent="forceSuggestion(suggestion)">
                    <Badge color="purple" small>
                        {{ dateOfBirth(suggestion.dob) }}
                    </Badge>
                    {{ useFullname(suggestion, { initials: true, salutation: true }) }}
                </li>
            </ul>
        </teleport>
    </div>
</template>

<script setup lang="ts">
    import { formatDate } from '@/composables/use-time-format';
    import useFullname from '@/composables/useFullname';
    import type { Patient } from '@nietpluis/common/interfaces/person';
    import { vOnClickOutside } from '@vueuse/components';
    import { computed, onMounted, ref } from 'vue';

    const acInput = ref<HTMLInputElement>();

    const props = defineProps({
        spellcheck: {
            type: Boolean,
            default: true,
            required: false,
        },

        autocapitalize: {
            type: String,
            default: 'off',
            required: false,
        },

        placeholder: {
            type: String,
            default: '',
            required: false,
        },

        source: {
            type: Array<Patient>,
            required: true,
        },

        value: {
            type: String,
            default: '',
            required: false,
        },

        styles: {
            type: String,
            default: '',
        },
    });

    const emit = defineEmits<{
        (e: 'input', v: string): void;
        (e: 'change', v: Event): void;
    }>();

    const text = ref(props.value);
    const selectionStart = ref(0);
    const currentSuggestionIndex = ref(-1);
    const suggestionsVisible = ref(false);
    const dirty = ref(false);
    const acInputBoundingRect = ref<DOMRect | undefined>();

    onMounted(() => {
        updateSuggestions(
            {
                target: acInput,
            },
            false,
        );
    });

    const dateOfBirth = (dob: Date | undefined): string => formatDate(dob);

    const hideSuggestions = () => {
        suggestionsVisible.value = false;
        currentSuggestionIndex.value = -1;
    };

    const normalizeString = (string: string) =>
        string
            .normalize('NFD')
            .replace(/[\u0300-\u036f]/g, '')
            .toLowerCase();

    const suggestions = computed(() => {
        const input = normalizeString(text.value.substring(0, selectionStart.value));
        return (
            props.source
                .filter((entry: Patient) => {
                    const name = normalizeString(entry.details.lastName);
                    return normalizeString(entry.details.lastName).startsWith(input) && input !== name;
                })
                // We only want the top 10 suggestions.
                .slice(0, 10)
        );
    });

    function updateSuggestions(event: any, setDirty = true) {
        dirty.value = setDirty;
        acInputBoundingRect.value = acInput.value?.getBoundingClientRect();

        // Hide suggestions if ESC pressed.
        if (event.code && event.code === 'Escape') {
            event.preventDefault();
            suggestionsVisible.value = false;
            currentSuggestionIndex.value = -1;
            return;
        }

        // As suggestions is a reactive property, this implicitly
        // causes suggestions to update.
        selectionStart.value = acInput.value?.selectionStart ?? -1;
        suggestionsVisible.value = dirty.value;
    }

    const onInput = (e: Event) => {
        emit('input', (e.target as HTMLInputElement).value);

        if (suggestions.value.length === 1) {
            const normalizedName = normalizeString(suggestions.value[0].details.lastName);
            if (normalizedName === normalizeString(text.value)) {
                text.value = suggestions.value[0].details.lastName;
                emit('input', suggestions.value[0]?.id);
            }
        }
        updateSuggestions(e);
    };

    function forceSuggestion(suggestion: Patient) {
        text.value = suggestion.details.lastName;

        selectionStart.value = text.value.length;
        suggestionsVisible.value = true;
        currentSuggestionIndex.value = -1;

        suggestion?.id && emit('input', suggestion.id);
    }

    function handleKeystroke(event: any) {
        switch (event.code) {
            case 'ArrowUp':
                event.preventDefault();

                currentSuggestionIndex.value = currentSuggestionIndex.value - 1 >= 0 ? currentSuggestionIndex.value - 1 : 0;
                break;

            case 'ArrowDown':
                event.preventDefault();

                currentSuggestionIndex.value =
                    currentSuggestionIndex.value < suggestions.value.length - 1
                        ? currentSuggestionIndex.value + 1
                        : suggestions.value.length - 1;
                break;

            case 'Enter':
                event.preventDefault();

                if (currentSuggestionIndex.value > -1)
                    forceSuggestion(suggestions.value.find((_item, index) => index === currentSuggestionIndex.value)!);
                break;

            case 'Tab': {
                event.preventDefault();

                const activeSuggestion = suggestions.value[currentSuggestionIndex.value >= 0 ? currentSuggestionIndex.value : 0];
                suggestionsVisible.value = false;

                if (!activeSuggestion) return;

                forceSuggestion(activeSuggestion);
                break;
            }
        }
    }
</script>

<style lang="scss" scoped>
    .autocomplete-wrapper {
        display: flex;
        flex: 1 1 0%;
        flex-shrink: 0;
        position: relative;
        white-space: nowrap;
    }
</style>
<style>
    ul.suggestions {
        --tw-shadow: 0 10px 15px -3px rgb(0 0 0/10%), 0 4px 6px -4px rgb(0 0 0/10%);
        --tw-shadow-colored: 0 10px 15px -3px var(--tw-shadow-color), 0 4px 6px -4px var(--tw-shadow-color);

        background-color: hsl(var(--popover-color));
        border: 1px solid hsl(var(--divider-color));
        border-radius: 0 8px 8px;
        box-shadow: var(--tw-ring-offset-shadow, 0 0 #00000000), var(--tw-ring-shadow, 0 0 #00000000), var(--tw-shadow);
        left: -1px;
        max-height: 11.5rem;
        overflow-y: auto;
        position: absolute;
        right: -1px;
        top: calc(100% + 1px);
        z-index: 999;

        li {
            align-items: center;
            cursor: pointer;
            display: flex;
            gap: 1rem;
            justify-content: start;
            padding: 0.5rem 1rem;
            transition: color 300ms ease-in-out, background-color 300ms ease-in-out;
            width: 100%;

            &:last-child {
                border-radius: 0 0 0 8px;
            }

            &:hover,
            &:focus,
            &.active {
                background-color: hsl(var(--purple-400) / 10%);
                color: hsl(var(--theme-color-dark));
                cursor: pointer;
            }
        }
    }

    input:focus + ul.suggestions {
        cursor: pointer;
    }
</style>
