<template>
    <section class="practice">
        <div class="input-wrapper gc-6 gr-2">
            <label for="practiceName">Praktijknaam</label>
            <input id="practiceName" v-model="organizationName" type="text" @keyup="findOrganization" autofocus />
        </div>

        <div v-if="practices.results.length" class="results gc-6 gr-3">
            <div v-if="practices.results.length > 1">Selecteer een praktijk:</div>
            <div v-if="practices.results.length == 1">Geselecteerde praktijk:</div>
            <div
                v-for="result in practices.results"
                :key="result.id"
                class="result"
                :class="{ 'result--active': organizationId == result.id }"
                @click="updateOrganization(result.id)">
                <span class="name">{{ result.name }}</span>
            </div>
        </div>
    </section>
</template>

<script setup lang="ts">
    import useAutofocus from '@/composables/use-autofocus';
    import { UPDATE_MODEL_VALUE } from '@/constants/events';
    import { usePracticeStore } from '@/stores/practice.store';
    import { ref } from 'vue';

    useAutofocus();

    const practices = usePracticeStore();
    const emit = defineEmits([UPDATE_MODEL_VALUE]);

    const organizationName = ref('');
    const organizationId = ref('');

    const updateOrganization = (id: string): void => {
        organizationId.value = id;
        console.log(organizationId.value);
        emit(UPDATE_MODEL_VALUE, organizationId.value);
    };

    const findOrganization = async (): Promise<void> => {
        if (organizationName.value.length < 3) return;

        await practices.findOrganizationByName({ name: organizationName.value });

        if (practices.results.length === 1) {
            organizationId.value = practices.results.first().id;
            emit(UPDATE_MODEL_VALUE, organizationId.value);
        }
    };
</script>

<style scoped lang="scss">
    .practice {
        .results {
            margin-top: 0.5rem;
            min-width: 320px;

            strong {
                color: hsl(var(--theme-color));
            }

            .result {
                cursor: pointer;
                padding: 0.5rem 0;

                .name {
                    margin-left: 0;
                }

                &.result--active {
                    color: hsl(var(--theme-color));
                }
            }
        }
    }
</style>
