import type { Alert, Snackbar } from '@/interfaces/Alert';
import { defineStore } from 'pinia';
import { reactive } from 'vue';

interface State {
    snackbars: Snackbar[];
    alerts: Alert[];
    lastActivity: number;
}

export const useMainStore = defineStore('main', {
    state: (): State => ({
        snackbars: [] as Snackbar[],
        alerts: [] as Alert[],
        lastActivity: Date.now(),
    }),
    getters: {
        count: (state) => state.alerts.length,
        getLastActivity: (state) => state.lastActivity,
    },
    actions: {
        getTimestamp: () => {
            return new Date().getTime().toString() + Math.random().toString();
        },

        setLastActivity() {
            this.lastActivity = Date.now();
        },

        addAlert(alert: Omit<Alert, 'active'>): void {
            const alertToAdd: Alert = reactive({ ...alert, active: false, timestamp: this.getTimestamp() });
            this.alerts.push(alertToAdd);

            setTimeout(() => {
                alertToAdd.active = true;

                if (alert.persistent !== true) {
                    setTimeout(() => {
                        alertToAdd.leaving = true;

                        setTimeout(() => {
                            this.alerts.shift();
                        }, 2500);
                    }, alert.timeout ?? 5000);
                }
            }, 300);
        },
    },
});
