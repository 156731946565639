<template>
    <div class="v-card-subtitle"><slot /></div>
</template>

<style lang="scss" scoped>
    .v-card-subtitle {
        padding: 0 var(--v-card-padding);

        align-items: center;
        display: flex;
        flex-wrap: wrap;
    }
</style>
