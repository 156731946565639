<template>
    <form @submit.prevent="handleSubmit">
        <VCard class="modal" large>
            <VCardTitle class="modal-title">Huisarts toevoegen</VCardTitle>
            <VCardBody class="modal-content">
                <Personalia v-model="gp" @update:modelValue="onUpdateGP" :dob="false" type="gp" />
                <UserForm v-model:gp="email" @update:email="onUpdateEmail" :role="false" />
                <SelectOrganizationForm v-model="organizationId" @update:modelValue="onUpdateOrganizationId" />
                <ContactInfoForm v-model="gp.details.contact" @update:modelValue="onUpdateContactInfo" />
            </VCardBody>
            <VCardActions class="modal-footer">
                <button secondary type="button" @click="cancel">Annuleren</button>
                <button type="submit">Toevoegen</button>
            </VCardActions>
            <Loader :state="state" @finished="reset" />
        </VCard>
    </form>
</template>

<script setup lang="ts">
    import { useGPStore } from '@/stores/gp.store';
    import { useMainStore } from '@/stores/main.store';
    import type { ContactInfoDTO } from '@nietpluis/common/interfaces/common';
    import type { CreateGpDTO, GP } from '@nietpluis/common/interfaces/person';
    import type { AxiosError } from 'axios';
    import { reactive, ref } from 'vue';
    import { useRouter } from 'vue-router';
    import type { LoadingState } from '../Loader.vue';

    const emit = defineEmits(['cancel']);

    const mainStore = useMainStore();
    const GPs = useGPStore();
    const router = useRouter();

    let gp = reactive<CreateGpDTO>({
        type: 'gp',
        details: {
            firstName: '',
            lastName: '',
            gender: undefined,
            initials: '',
            contact: [],
        },
        organizationId: '',
        email: '',
    });

    let email = ref('');
    const organizationId = ref('');

    const state = ref<LoadingState>('idle');

    let reset = () => {
        cancel();
    };

    const onUpdateGP = (value: GP): void => {
        // gp = value;
        // console.log(gp);
    };

    const onUpdateEmail = (value: string): void => {
        email.value = value;
    };

    const onUpdateOrganizationId = (value: string): void => {
        organizationId.value = value;
    };

    const onUpdateContactInfo = (info: ContactInfoDTO[]): void => {
        gp.details.contact = info.map((contact: ContactInfoDTO) => {
            return {
                type: contact.type,
                value: contact.value,
            };
        });
    };

    const handleSubmit = async (): Promise<void> => {
        state.value = 'loading';

        if (!organizationId.value) {
            mainStore.addAlert({
                message: 'Er is geen praktijk gekozen',
                type: 'error',
            });
            state.value = 'idle';
            return;
        }

        const newGP: CreateGpDTO = {
            type: 'gp',
            email: email.value,
            details: {
                firstName: gp.details.firstName,
                lastName: gp.details.lastName,
                initials: gp.details.initials,
                gender: gp.details.gender,
                contact: gp.details.contact,
            },
            organizationId: organizationId.value,
        };

        newGP.details.contact.push({
            type: 'email',
            value: email.value,
        } as ContactInfoDTO);

        try {
            const { data }: { data: GP } = await GPs.create(newGP);

            if (data) {
                state.value = 'success';

                reset = async () => {
                    await GPs.fetchGPs();
                    router.push({ name: 'Contact', params: { filter: 'gp', id: data.id } });
                    cancel();
                };
            }
        } catch (error) {
            state.value = 'error';
            const err: AxiosError = error as AxiosError;

            if (err.response) {
                console.log(err);

                mainStore.addAlert({
                    message: 'Het is niet gelukt om deze huisarts toe te voegen.<br />Probeer later opnieuw.',
                    type: 'error',
                });
            } else if (err.request) {
                mainStore.addAlert({
                    message: 'Er kon geen verbinding gemaakt worden met de server.<br />Probeer later opnieuw.',
                    type: 'error',
                });
            } else {
                // Something happened in setting up the request and triggered an Error
                console.log('Error', err.message);
            }

            reset = () => {
                state.value = 'idle';
            };
        }
    };

    const cancel = () => {
        state.value = 'idle';
        emit('cancel');
    };
</script>

<style scoped lang="scss">
    .v-card-title {
        line-height: 1;
        margin-top: 0;
        padding: 0;
    }

    .v-card-body {
        display: grid;
        grid-template-rows: repeat(3, auto);
    }

    .modal-content {
        padding: 0;
    }

    .v-card-actions {
        padding: 0;
    }

    :deep(section) {
        display: grid;
        grid-gap: 1rem;
        grid-template-columns: repeat(12, 1fr);

        &:first-of-type {
            margin-top: -1rem;
        }

        label {
            @include font-base;

            font-weight: 400;
            margin-left: 4px;
        }
    }
</style>
