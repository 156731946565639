import type { ResultResponse } from '@/interfaces/Response';
import type { Patient, User } from '@nietpluis/common/interfaces/person';
import { Report, SystemAction, type ReportUpdate } from '@nietpluis/common/interfaces/report';
import axios, { type AxiosResponse } from 'axios';
import { defineStore } from 'pinia';
import type { ReportFilter } from './filter.store';

interface ReportState {
    patients: Patient[];
    reports: Report[];
    report: Report | undefined;
    openReports: number;
    newReport: {
        patient: Patient | undefined;
        subject: string;
        description: string;
        expectation: string;
    };
}

export const useReportStore = defineStore('report', {
    state: (): ReportState => ({
        patients: [],
        report: undefined,
        reports: [],
        openReports: 0,
        newReport: {
            patient: undefined,
            subject: '',
            description: '',
            expectation: '',
        },
    }),
    getters: {},
    actions: {
        async fetchReports(filter: ReportFilter): Promise<void> {
            const { data }: { data: ResultResponse<Patient> } = await axios.get(`/report/${filter}?sort=DESC`);
            this.patients = data.results;

            if (filter === 'open') {
                this.openReports = data.count;
            }
        },

        async fetchReportById(id: string): Promise<void> {
            if (!id) {
                throw Error('No report id given');
            }

            const foundReport = this.reports.find((report) => report.id === id);

            if (!foundReport) {
                const { data }: { data: Report } = await axios.get(`/report/${id}`);
                this.reports.push(data);
                this.report = data;
            } else {
                this.report = foundReport;
            }
        },

        async fetchReportsForUser(): Promise<void> {
            const { data } = await axios.get(`/report/me`); /// TODO: change to /user/me/report

            this.reports = data.results;
        },

        async setReportStatus(status: boolean): Promise<void> {
            if (!this.report) {
                throw Error('No report selected');
            }

            await axios.patch(`/report/${this.report.id}`, {
                isActive: status,
            });

            this.report.isActive = status;
        },

        async setReportAssignee(assignee?: User): Promise<void> {
            if (!this.report) {
                throw Error('No report selected');
            }

            this.patients.forEach((patient) => {
                // eslint-disable-next-line no-param-reassign
                patient.reports = patient.reports.map((report) => {
                    if (report.id === this.report?.id) {
                        const newReport = report as Report;
                        newReport.assignee = assignee;

                        return newReport;
                    }

                    return report;
                });
            });

            const result = await axios.patch(`/report/${this.report?.id}`, {
                assigneeId: assignee?.id ?? null,
            });

            if (result.data.first()) {
                this.report.assignee = assignee;
            } else {
                throw Error('Something went wrong');
            }
        },

        async createUpdate(options: { reportId: string; text: string }): Promise<AxiosResponse<ReportUpdate>> {
            const updateDto = {
                text: options.text,
                action: SystemAction.None,
            };

            if (!options.reportId) {
                throw Error('No report Id given');
            }

            return axios.post(`/report/${options.reportId}/update`, updateDto);
        },
    },
});
