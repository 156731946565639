import NewReport from '@/components/Dialogs/NewReport.vue';
import { useAppAbility } from '@/composables/useAppAbility';
import type { Actions, Subjects } from '@/config/ability';
import Dashboard from '@/layouts/Dashboard.vue';
import { useCurrentUserStore } from '@/stores/currentUser.store';
import Reports from '@/views/Reports/Reports.vue';

import {
    createRouter,
    createWebHistory,
    type NavigationGuardNext,
    type RouteLocationNormalized,
    type RouteLocationRaw,
    type RouteRecordRaw,
} from 'vue-router';

declare module 'vue-router' {
    interface RouteMeta {
        // is optional
        isAdmin?: boolean;
        // must be declared by every route
        requiresAuth?: boolean;
        title: string;
    }
}

const can = (action: Actions, subject: Subjects, redirect = '/') => {
    const { can } = useAppAbility();

    return can(action, subject) ? true : redirect;
};

const cannot = async (action: Actions, subject: Subjects, redirect = '/') => {
    const { cannot } = useAppAbility();

    return cannot(action, subject) ? true : redirect;
};

const routes: Array<RouteRecordRaw> = [
    {
        path: '/',
        name: 'Dashboard',
        component: Dashboard,
        redirect: { name: 'Meldingen' },
        meta: {
            requiresAuth: true,
            title: 'Dashboard',
        },
        children: [
            {
                path: 'reports',
                name: 'ReporterView',
                beforeEnter: () => cannot('manage', 'Report', '/meldingen'),
                component: () => import('@/views/Reports/ReporterDashboard.vue'),
                meta: {
                    title: 'Meldingen',
                },
            },
            {
                path: 'meldingen',
                name: 'Meldingen',
                beforeEnter: () => can('manage', 'Report', '/reports'),
                component: Reports,
                meta: {
                    title: 'Meldingen',
                },
                children: [
                    {
                        path: ':id',
                        name: 'MeldingenPatient',
                        component: () => import('@/views/Rolodex/Contact.vue'),
                        meta: {
                            title: 'Patiënt',
                        },
                    },
                    {
                        path: ':id/report/:reportId',
                        name: 'Melding',
                        components: {
                            default: () => import('@/views/Rolodex/Contact.vue'),
                            report: () => import('@/views/Reports/Report.vue'),
                        },
                        meta: {
                            title: 'Melding',
                        },
                    },
                ],
            },
            {
                path: 'patienten',
                name: 'Patienten',
                beforeEnter: () => can('read', 'Patient'),
                component: () => import('@/views/Patients/Patients.vue'),
                meta: {
                    title: 'Patiënten',
                },
                children: [
                    {
                        path: ':id',
                        name: 'Patient',
                        component: () => import('@/views/Rolodex/Contact.vue'),
                        meta: {
                            title: 'Patiënt',
                        },
                    },
                    {
                        path: ':id/report/:reportId',
                        name: 'Report',
                        components: {
                            default: () => import('@/views/Rolodex/Contact.vue'),
                            report: () => import('@/views/Reports/Report.vue'),
                        },
                        meta: {
                            title: 'Melding',
                        },
                    },
                ],
            },
            {
                path: 'rolodex',
                name: 'Rolodex',
                beforeEnter: async () => {
                    const rolodexSubjects: Subjects[] = ['Practice', 'Homecare', 'GP', 'Patient', 'User'];
                    const canManage = rolodexSubjects.map((subject) => can('manage', subject, ''));

                    return canManage.every(Boolean) || '/reports';
                },
                component: () => import('@/views/Rolodex/Rolodex.vue'),
                meta: {
                    title: 'Rolodex',
                },
                children: [
                    {
                        path: ':filter/:id?',
                        name: 'Contact',
                        component: () => import('@/views/Rolodex/Contact.vue'),
                    },
                ],
            },
        ],
    },
    {
        path: '/report/new',
        name: 'NewReport',
        beforeEnter: () => can('create', 'Report'),
        component: NewReport,
        meta: {
            requiresAuth: true,
            title: 'Nieuwe melding',
        },
    },
    {
        path: '/auth',
        name: 'Auth',
        component: () => import('@/layouts/Auth.vue'),
        meta: {
            requiresAuth: false,
            title: 'Authorization',
        },
        children: [
            {
                path: 'login',
                name: 'Login',
                component: () => import('@/views/auth/Login.vue'),
                meta: { title: 'Inloggen' },
            },
            {
                path: 'activeren/:userId/:token',
                name: 'Activate',
                component: () => import('@/views/auth/Activate.vue'),
                meta: { title: 'Account activeren' },
            },
            {
                path: 'wachtwoord-vergeten',
                name: 'ForgotPassword',
                component: () => import('@/views/auth/ForgotPassword.vue'),
                meta: { title: 'Wachtwoord vergeten' },
            },
            {
                path: 'wachtwoord-wijzigen/:userId/:detailsId/:token',
                name: 'ResetPassword',
                component: () => import('@/views/auth/ResetPassword.vue'),
                meta: { title: 'Wachtwoord wijzigen' },
            },
            {
                path: 'email-activeren/:userId/:token',
                name: 'ActivatePerson',
                component: () => import('@/views/auth/Activate.vue'),
                props: { person: true },
                meta: { title: 'Emailadres activeren' },
            },
            {
                path: 'activatie-link',
                name: 'ResendAccountActivation',
                component: () => import('@/views/auth/ResendActivation.vue'),
                meta: { title: 'Nieuwe activatie link aanvragen' },
            },
            {
                path: 'email-activatie-link',
                name: 'ResendEmailActivation',
                component: () => import('@/views/auth/ResendActivation.vue'),
                props: { person: true },
                meta: { title: 'Nieuwe activatie link aanvragen' },
            },
        ],
    },
    {
        path: '/:pathMatch(.*)*',
        name: 'NotFound',
        component: () => import('@/views/NotFound.vue'),
        meta: {
            title: 'Pagina niet gevonden',
        },
    },
];

const router = createRouter({
    routes,
    history: createWebHistory(import.meta.env.BASE_URL),
    scrollBehavior() {
        document?.querySelector('main')?.scrollTo({
            top: 0,
            behavior: 'smooth',
        });

        return false;
    },
});

const checkAccessTokenValidity = async (
    to: RouteLocationNormalized,
    from: RouteLocationNormalized,
    next: NavigationGuardNext,
): Promise<RouteLocationRaw> => {
    const user = useCurrentUserStore();

    try {
        await user.ping();
        return '';
    } catch (error) {
        return {
            name: 'Login',
            params: { nextUrl: to.fullPath },
        };
    }
};

router.beforeEach(async (to, from, next): Promise<void> => {
    document.title = `${to.meta.title} - Niet Pluis`;

    if (to.meta.requiresAuth) {
        const nextLocation = await checkAccessTokenValidity(to, from, next);
        if (nextLocation === '') {
            next();
        } else {
            next(nextLocation);
        }
    } else {
        next();
    }
});

export default router;
