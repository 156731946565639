import type { ResultResponse } from '@/interfaces/Response';
import axios from 'axios';
import { wrapper } from 'axios-cookiejar-support';
import { defineStore } from 'pinia';
import { CookieJar } from 'tough-cookie';

wrapper(axios);

const jar = new CookieJar();
axios.defaults.withCredentials = true;

export type Role = {
    id: string;
    name: string;
};

export const useRoleStore = defineStore('role', {
    state: () => ({
        roles: [] as Role[],
    }),
    getters: {
        getRoles: (state) => state.roles.toSorted((a: Role, b: Role) => a.name.localeCompare(b.name)),
    },
    actions: {
        async fetchRoles(): Promise<void> {
            const { data }: { data: ResultResponse<Role> } = await axios.get('/role', { jar, withCredentials: true });
            this.roles = data.results;
        },
    },
});
