<template>
    <div class="container">
        <Navbar />
        <router-view />
    </div>
</template>

<script setup lang="ts">
    import Navbar from '@/components/Navbar.vue';
    import { useMainStore } from '@/stores/main.store';
    import { useReportStore } from '@/stores/report.store';
    import { onBeforeMount, onBeforeUnmount, onMounted } from 'vue';

    const reports = useReportStore();
    const main = useMainStore();

    const periodicFetch = setInterval(async () => {
        try {
            if (main.getLastActivity <= Date.now() + 60000) {
                await reports.fetchReports('open');
            } else {
                console.log('No activity, not fetching reports...');
                clearInterval(periodicFetch);
            }
        } catch (e) {
            console.error(e); // User is probably not logged in anymore,
            clearInterval(periodicFetch);
            // We should clear the access token and refresh token here,
            // then redirect the user to the login page.
        }
    }, 60000);

    onMounted(() => {
        window.addEventListener('mousemove', main.setLastActivity);
    });

    onBeforeMount(async () => {
        if (reports.patients.length === 0) {
            try {
                await reports.fetchReports('open');
            } catch (e) {}
        }
    });

    onBeforeUnmount(() => {
        clearInterval(periodicFetch);
        window.removeEventListener('mousemove', main.setLastActivity);
    });
</script>

<style scoped>
    .container {
        display: grid;
        grid-gap: 0;
        grid-template-areas:
            'nav'
            'main';
        grid-template-columns: 100vw;
        grid-template-rows: auto 1fr;
        min-height: 100vh;
        row-gap: 0;
    }
</style>
