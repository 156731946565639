<template>
    <form @submit.prevent="handleSubmit">
        <v-card class="modal" large>
            <v-card-title class="modal-title">Gebruiker toevoegen</v-card-title>
            <v-card-body class="modal-content">
                <Personalia v-model="userDetails" @update:modelValue="onUpdateUser" :dob="false" :gender="false" type="user" />
                <UserForm
                    v-model:user="email"
                    v-model:roleId="roleId"
                    @update:email="onUpdateEmail"
                    @update:roleId="onUpdateRoleId"
                    @update:organizationId="onUpdateOrganizationId"
                    :error="error"
                    :role="true" />
            </v-card-body>
            <v-card-actions class="modal-footer">
                <button secondary type="button" @click="cancel">Annuleren</button>
                <button type="submit">Toevoegen</button>
            </v-card-actions>
            <Loader :state="state" @finished="reset" />
        </v-card>
    </form>
</template>

<script setup lang="ts">
    import Loader, { type LoadingState } from '@/components/Loader.vue';
    import { VCard, VCardActions, VCardBody, VCardTitle } from '@/components/v-card';
    import { useGPStore } from '@/stores/gp.store';
    import { useMainStore } from '@/stores/main.store';
    import { useUserStore } from '@/stores/user.store';
    import type { CreateDetailsDTO, CreatePersonDTO, CreateUserDTO, User } from '@nietpluis/common/interfaces/person';
    import type { AxiosError, AxiosResponse } from 'axios';
    import { ref } from 'vue';
    import { useRouter } from 'vue-router';
    import type { Personalia } from '../Contact/Personalia.vue';
    import UserForm from '../Contact/UserForm.vue';

    const emit = defineEmits(['cancel']);

    const mainStore = useMainStore();
    const GPs = useGPStore();
    const users = useUserStore();
    const router = useRouter();

    const userDetails = ref<CreateDetailsDTO>();
    const roleId = ref('');
    const organizationId = ref<string>('');
    const email = ref('');

    const state = ref<LoadingState>('idle');
    const error = ref<'email' | undefined>();

    const onUpdateUser = (value: Personalia<CreatePersonDTO>): void => {
        userDetails.value = value.details;
    };

    const onUpdateRoleId = (value: string): void => {
        roleId.value = value;
    };

    const onUpdateEmail = (value: string): void => {
        email.value = value;
    };

    const onUpdateOrganizationId = (value: string): void => {
        organizationId.value = value;
    };

    let reset = () => {
        state.value = 'idle';
    };

    const handleSubmit = async (): Promise<void> => {
        state.value = 'loading';

        if (!userDetails.value?.initials || !userDetails.value?.firstName || !userDetails.value?.lastName) {
            mainStore.addAlert({
                message: 'U heeft niet alle gegevens ingevuld.',
                type: 'error',
            });

            return;
        }

        const newUser: CreateUserDTO = {
            type: 'user',
            email: email.value,
            details: {
                firstName: userDetails.value?.firstName,
                lastName: userDetails.value?.lastName,
                initials: userDetails.value?.initials,
                gender: 'other',
            },
            roleId: roleId.value,
            organizationId: organizationId.value,
        };

        try {
            const { data }: { data: User } = await users.create(newUser);

            if (data) {
                state.value = 'success';

                reset = async () => {
                    await users.fetchUsers();
                    router.push({ name: 'Contact', params: { filter: 'user', id: data.id } });
                    state.value = 'idle';
                    emit('cancel');
                };
            }
        } catch (error) {
            state.value = 'error';

            const err: AxiosError = error as AxiosError;

            if (err.response) {
                handleError(err.response as AxiosResponse<ErrorResponse>);
            } else if (err.request) {
                mainStore.addAlert({
                    message: 'Er kon geen verbinding gemaakt worden met de server.<br />Probeer later opnieuw.',
                    type: 'error',
                });
            } else {
                // Something happened in setting up the request and triggered an Error
                console.log('Error', err.message);
            }

            reset = () => {
                state.value = 'idle';
            };
        }
    };

    type ErrorResponse = {
        message: string;
        statusCode: number;
        error: string;
    };

    const handleError = (response: AxiosResponse<ErrorResponse>) => {
        if (response.data.statusCode === 400) {
            if (response.data.message === 'Email already in use') {
                console.log('setting error to email');
                error.value = 'email';
            }
            state.value = 'idle';
        }
    };

    const cancel = () => {
        state.value = 'idle';
        emit('cancel');
        GPs.$reset();
    };
</script>

<style scoped lang="scss">
    .v-card-title {
        line-height: 1;
        margin-top: 0;
        padding: 0;
    }

    .v-card-body {
        display: grid;
        grid-template-rows: repeat(3, auto);
    }

    .modal-content {
        padding: 0;
    }

    .v-card-actions {
        padding: 0;
    }

    :deep(section) {
        display: grid;
        grid-column-gap: 1rem;
        grid-row-gap: 1rem;
        grid-template-columns: repeat(12, 1fr);

        &:first-of-type {
            margin-top: -1rem;
        }

        label {
            @include font-base;

            font-weight: 400;
            margin-left: 4px;
        }
    }
</style>
