<template>
    <div class="v-card-actions"><slot /></div>
</template>

<style lang="scss" scoped>
    .v-card-actions {
        column-gap: 0.75rem;
        display: flex;
        justify-content: flex-end;
        padding: var(--v-card-padding);
        row-gap: 1rem;
    }
</style>
