<template>
    <section class="gp">
        <div class="input-wrapper gc-6 gr-2">
            <label for="gpName">Achternaam huisarts</label>
            <input id="gpName" v-model="gpLastName" type="text" @keyup="findGP" v-format.capitalize.non-digit />
        </div>

        <div v-if="GPs.results" class="results gc-12 gr-3">
            <div v-if="GPs.results.length > 1">Selecteer een huisarts:</div>
            <div v-if="GPs.results.length == 1">Geselecteerde huisarts:</div>
            <div
                v-for="result in (GPs.results as GP[])"
                :key="result.id"
                class="result"
                :class="{ 'result--active': gpId === result.id }"
                @click="updateGP(result.id)">
                <badge :color="gpId === result.id ? 'purple' : undefined" small>
                    {{ result.organization?.name }}
                </badge>
                <span class="name">{{ useFullname(result, { initials: true, salutation: true }) }}</span>
            </div>
        </div>
    </section>
</template>

<script setup lang="ts">
    import useFullname from '@/composables/useFullname';
    import { UPDATE_MODEL_VALUE } from '@/constants/events';
    import { useGPStore } from '@/stores/gp.store';
    import type { GP } from '@nietpluis/common/interfaces/person';
    import { onMounted, ref } from 'vue';
    const GPs = useGPStore();
    const emit = defineEmits([UPDATE_MODEL_VALUE]);

    const gpLastName = ref('');
    const gpId = ref('');

    onMounted(() => GPs.$reset());
    const updateGP = (id: string): void => {
        gpId.value = id;
        emit(UPDATE_MODEL_VALUE, id);
    };

    const findGP = async (): Promise<void> => {
        if (gpLastName.value.length < 3) return;

        await GPs.findGP({ lastName: gpLastName.value });

        if (GPs.results.length === 1) {
            gpId.value = GPs.results.first().id;

            updateGP(gpId.value);
        }
    };
</script>

<style scoped lang="scss">
    .gp {
        .results {
            margin-top: 0.5rem;
            min-width: 320px;

            strong {
                color: hsl(var(--theme-color));
            }

            .result {
                cursor: pointer;
                padding: 0.5rem 0;

                .name {
                    margin-left: 1rem;
                }

                &.result--active {
                    color: hsl(var(--theme-color));
                }
            }
        }
    }
</style>
