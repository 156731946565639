<template>
    <svg class="icon" :title="title">
        <path v-for="(path, index) in paths" :key="index" :d="path" />
    </svg>
</template>

<script setup lang="ts">
    import { computed } from 'vue';

    const icons: { [key: string]: string[] } = {
        priority: [
            'M6.76795 1C7.53775 -0.333332 9.46225 -0.333334 10.2321 1L16.7272 12.25C17.497 13.5833 16.5348 15.25 14.9952 15.25H2.00481C0.46521 15.25 -0.497042 13.5833 0.272758 12.25L6.76795 1Z',
        ], // eslint-disable-line  max-len
        download: ['M7.00016 4H9.66683L5.00016 8.66667L0.333496 4H3.00016V0H7.00016V4ZM0.333496 11.3333V10H9.66683V11.3333H0.333496Z'],
        unread: [
            'M16.1485 6.90477L6.46855 16.6825C6.27998 16.873 5.96569 17 5.71426 17C5.39998 17 5.14855 16.873 4.95998 16.6825L1.37712 13.0635C0.937119 12.6191 0.937119 11.9206 1.37712 11.4762C1.81712 11.0318 2.50855 11.0318 2.94855 11.4762L5.77712 14.3333L14.5771 5.31746C15.0171 4.87302 15.7085 4.87302 16.1485 5.31746C16.5885 5.76191 16.5885 6.46032 16.1485 6.90477Z', // eslint-disable-line  max-len
            'M22.6856 6.90477L13.0056 16.6825C12.817 16.873 12.5027 17 12.2513 17C11.937 17 11.6856 16.873 11.497 16.6825L10.0513 15.2222L11.6227 13.6349L12.3141 14.3333L21.1141 5.31746C21.5541 4.87302 22.2456 4.87302 22.6856 5.31746C23.1256 5.76191 23.1256 6.46032 22.6856 6.90477Z', // eslint-disable-line  max-len
        ],
        chevron: [
            'M1.92127 0.58782C1.59662 0.263176 1.07027 0.263176 0.745627 0.58782C0.421233 0.912214 0.420946 1.43807 0.744986 1.76282L3.27032 4.29366C3.65981 4.68401 3.65981 5.31599 3.27032 5.70634L0.744987 8.23718C0.420947 8.56193 0.421233 9.08779 0.745627 9.41218C1.07027 9.73682 1.59662 9.73682 1.92127 9.41218L5.62634 5.70711C6.01687 5.31658 6.01686 4.68342 5.62634 4.29289L1.92127 0.58782Z',
        ], // eslint-disable-line  max-len
        handup: [
            'M16.9278 3.43566C16.5596 3.43566 16.2103 3.51711 15.8959 3.66195C15.6827 2.51302 14.6737 1.64007 13.4639 1.64007C13.0838 1.64007 12.7237 1.72637 12.4016 1.88016C12.1348 0.801897 11.1595 0 9.99996 0C8.84044 0 7.86515 0.801946 7.59833 1.88016C7.27628 1.72642 6.91609 1.64007 6.53604 1.64007C5.17176 1.64007 4.06182 2.75 4.06182 4.11429V10.1624C3.75853 10.0295 3.42391 9.9552 3.07213 9.9552C1.70784 9.9552 0.5979 11.0651 0.5979 12.4294V17.5175C0.5979 21.0919 3.50591 24 7.08037 24H12.9195C16.494 24 19.402 21.0919 19.402 17.5175V5.90989C19.402 4.54555 18.2921 3.43566 16.9278 3.43566ZM17.9175 17.5175C17.9175 20.2734 15.6754 22.5155 12.9195 22.5155H7.08037C4.32453 22.5155 2.08244 20.2734 2.08244 17.5175V12.4294C2.08244 11.8837 2.52641 11.4397 3.07213 11.4397C3.61784 11.4397 4.06182 11.8837 4.06182 12.4294V16.3882H5.54635V4.11429C5.54635 3.56858 5.99033 3.1246 6.53604 3.1246C7.08176 3.1246 7.52573 3.56858 7.52573 4.11429V11.9346H9.01027V2.47423C9.01027 1.92851 9.45425 1.48454 9.99996 1.48454C10.5457 1.48454 10.9897 1.92851 10.9897 2.47423V11.9346H12.4742V4.11429C12.4742 3.56858 12.9182 3.1246 13.4639 3.1246C14.0096 3.1246 14.4536 3.56858 14.4536 4.11429V11.9346H15.9381V5.90984C15.9381 5.36412 16.3821 4.92015 16.9278 4.92015C17.4735 4.92015 17.9175 5.36412 17.9175 5.90984V17.5175Z',
        ], // eslint-disable-line  max-len
        activeReports: [
            'M11.9164 17C10.9756 17 10.1603 16.8737 9.53314 16.6841C8.52965 16.3681 8.27878 15.7993 8.21607 15.3569C8.09063 14.2194 9.34499 13.461 11.0384 12.5131C11.4147 12.3235 11.9164 12.0075 12.3554 11.7547C11.9164 11.6915 11.352 11.6283 10.4739 11.5651C9.72129 11.5019 9.09411 11.4387 8.52965 11.3755C5.33105 13.3978 2.19516 15.4201 1.56798 15.7993C1.12896 16.1153 0.50178 15.9257 0.188191 15.4833C-0.125398 15.0409 0.0627554 14.409 0.50178 14.093C2.13244 13.0186 4.13941 11.8179 6.02094 10.554C4.70387 9.92198 4.20213 9.03722 4.01398 8.27886C3.63767 6.76213 4.26485 4.42384 9.28227 1.51678C13.2335 -0.821511 15.8049 -0.0631467 16.8084 1.32719C17.6237 2.40154 17.7492 4.42384 15.554 6.44615C14.9896 6.95172 13.2335 8.15247 11.1638 9.606C13.3589 9.79559 14.676 10.1116 14.9269 11.2491C15.2405 12.5131 13.7979 13.3346 12.0419 14.3458C11.7283 14.5354 11.2892 14.7882 10.9129 14.9777C12.3554 15.1673 14.8014 14.9145 16.3694 13.7138C16.8084 13.3978 17.4356 13.461 17.7492 13.9034C18.0628 14.3458 18 14.9777 17.561 15.2937C15.9931 16.5577 13.7352 17 11.9164 17ZM13.9234 2.02236C13.1081 2.02236 11.8537 2.33834 10.3485 3.2231C6.83628 5.3086 5.77007 6.95172 5.95823 7.77328C6.08366 8.21566 6.71084 8.78444 8.15335 9.16362C11.1638 7.20451 13.6725 5.49819 14.237 4.92942C15.3659 3.85507 15.554 2.90711 15.2405 2.46473C15.0523 2.21195 14.6133 2.02236 13.9234 2.02236Z',
        ], // eslint-disable-line  max-len
        'handup-disable': [
            'M17.8959 3.66195C18.2103 3.51711 18.5596 3.43566 18.9278 3.43566C20.1011 3.43566 21.0862 4.25652 21.3391 5.35414L19.9175 6.61421V5.90984C19.9175 5.36412 19.4735 4.92015 18.9278 4.92015C18.3821 4.92015 17.9381 5.36412 17.9381 5.90984V8.36866L16.4536 9.6845V4.11429C16.4536 3.56858 16.0096 3.1246 15.4639 3.1246C14.9182 3.1246 14.4742 3.56858 14.4742 4.11429V11.4389L13.915 11.9346H12.9897V2.47423C12.9897 1.92851 12.5457 1.48454 12 1.48454C11.4542 1.48454 11.0103 1.92851 11.0103 2.47423V11.9346H9.52573V4.11429C9.52573 3.56858 9.08176 3.1246 8.53604 3.1246C7.99033 3.1246 7.54635 3.56858 7.54635 4.11429V16.3882H6.06182V12.4294C6.06182 11.8837 5.61784 11.4397 5.07213 11.4397C4.52641 11.4397 4.08244 11.8837 4.08244 12.4294V17.5175C4.08244 18.4378 4.33245 19.3007 4.76811 20.0421L3.6412 21.0409C2.98146 20.0259 2.5979 18.8157 2.5979 17.5175V12.4294C2.5979 11.0651 3.70784 9.9552 5.07213 9.9552C5.42391 9.9552 5.75853 10.0295 6.06182 10.1624V4.11429C6.06182 2.75 7.17176 1.64007 8.53604 1.64007C8.91609 1.64007 9.27628 1.72642 9.59833 1.88016C9.86515 0.801946 10.8404 0 12 0C13.1595 0 14.1348 0.801897 14.4016 1.88016C14.7237 1.72637 15.0838 1.64007 15.4639 1.64007C16.6737 1.64007 17.6827 2.51302 17.8959 3.66195Z', // eslint-disable-line  max-len
            'M5.81976 23.1188C6.77818 23.6788 7.89247 24 9.08037 24H14.9195C18.494 24 21.402 21.0919 21.402 17.5175V9.3072L19.9175 10.623V17.5175C19.9175 20.2734 17.6754 22.5155 14.9195 22.5155H9.08037C8.34162 22.5155 7.63978 22.3543 7.00816 22.0654L5.81976 23.1188Z', // eslint-disable-line  max-len
            'M23.5613 3.00253C23.836 3.3125 23.8075 3.78652 23.4975 4.06127L1.49749 23.5613C1.18751 23.836 0.713499 23.8075 0.438748 23.4975C0.163997 23.1875 0.192552 22.7135 0.502528 22.4387L22.5025 2.93875C22.8125 2.664 23.2865 2.69255 23.5613 3.00253Z', // eslint-disable-line  max-len
        ],
        send: [
            'M2.46154 10.3691C2.17283 10.4894 1.98908 10.7763 2.0005 11.0888C2.01192 11.4014 2.21611 11.674 2.51283 11.7729L9.83405 14.2134L12.2744 21.5345C12.3733 21.8312 12.646 22.0354 12.9585 22.0468C13.2711 22.0582 13.5579 21.8745 13.6782 21.5858L20.9858 4.0476C21.0416 3.91845 21.0598 3.77608 21.0388 3.63751C21.0286 3.56984 21.009 3.50307 20.9799 3.43919C20.9366 3.34414 20.8733 3.25756 20.792 3.18631C20.7548 3.15366 20.7148 3.12503 20.6725 3.10065C20.5685 3.04045 20.4543 3.00761 20.3394 3.0012C20.2621 2.99681 20.1835 3.00437 20.1064 3.02472C20.0694 3.03443 20.0331 3.04699 19.9978 3.06237L2.46154 10.3691ZM17.0861 5.90055L4.88743 10.9833L10.2244 12.7623L17.0861 5.90055ZM18.1468 6.96118L11.2851 13.8229L13.064 19.1599L18.1468 6.96118Z', // eslint-disable-line  max-len
        ],
    };

    const props = defineProps<{
        name: string;
        title?: string;
    }>();

    const paths = computed((): string[] => {
        return icons[props.name];
    });
</script>

<style scoped lang="scss">
    .icon {
        height: 1.5rem;
        position: relative;
        top: 0.125rem;
        width: 1.5rem;

        path {
            fill: hsl(var(--grey-500));
            transition: fill 300ms;
        }

        &.is-active path {
            fill: hsl(var(--theme-color));
        }

        &[small] {
            height: 12px;
            margin-bottom: 1px;
            margin-right: 10px;
            width: 12px;
        }

        &[text-dark] path {
            fill: hsl(var(--grey-700));
        }
    }
</style>
