<template>
    <div class="loader-wrapper" :class="state">
        <svg class="checkmark" :class="state" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 50 50">
            <circle class="path" cx="25" cy="25" r="20"></circle>
            <path class="checkmark__check" fill="none" d="M14.1 27.2l7.1 7.2 16.7-16.8" @animationend="finished" />
            <g class="checkmark__error">
                <path fill="none" d="M 16.65,33.4 33.35,16.6" />
                <path fill="none" d="M 16.6,16.65 33.4,33.35" @animationend="finished" />
            </g>
        </svg>
    </div>
</template>

<script setup lang="ts">
    import { delay } from '@nietpluis/common/utils';

    export type LoadingState = 'idle' | 'loading' | 'success' | 'error';

    const emit = defineEmits(['finished']);

    type Props = {
        state: LoadingState;
    };

    defineProps<Props>();

    const finished = async () => {
        await delay(500);
        emit('finished');
    };
</script>

<style scoped lang="scss">
    .loader-wrapper {
        background-color: var(--loading-background);
        border-radius: 3px;
        display: grid;
        height: 100%;
        left: 0;
        opacity: 0;
        place-items: center;
        pointer-events: none;
        position: absolute;
        top: 0;
        transition: opacity 0.25s ease-in-out;
        width: 100%;

        &:not([no-bg], .idle) {
            opacity: 1;
            pointer-events: all;
            transition: opacity 0.5s ease-in-out;
        }

        &[no-bg] {
            background-color: transparent;
        }
    }

    .checkmark {
        --color: hsl(var(--theme-color));

        &.idle {
            --color: transparent;
        }

        &.error {
            --color: hsl(var(--red-500));
        }

        animation: rotate 2s linear infinite;
        border-radius: 50%;
        box-shadow: inset 0 0 0 var(--color);
        display: block;
        height: 50px;
        height: 56px;
        left: 50%;
        margin: -25px 0 0 -25px;
        position: absolute;
        stroke: white;
        stroke-miterlimit: 10;
        stroke-width: 2;
        top: 50%;
        transition: box-shadow 2s ease-in-out;
        width: 50px;
        width: 56px;
        z-index: 2;

        .path {
            // animation: loading 2s ease-in-out infinite;
            fill: none;
            stroke: var(--color);
            stroke-dasharray: 90, 150;
            stroke-dashoffset: -35;
            stroke-linecap: round;
            stroke-width: 2;
            transition: stroke-dashoffset 5s ease 0s;
        }

        &.loading {
            .path {
                animation: loading 2s ease-in-out infinite;
            }
        }

        &:not(.loading) {
            animation: fill 0.4s ease-in-out forwards, scale 0.3s ease-in-out both;

            .path {
                animation: none;
            }
        }

        &.success {
            .checkmark__check {
                animation: stroke 0.4s cubic-bezier(0.65, 0, 0.45, 1) 0.4s forwards;
            }
        }

        &.error {
            .checkmark__error {
                path:first-child {
                    animation: stroke 0.4s cubic-bezier(0.65, 0, 0.45, 1) 0.4s forwards;
                }

                path:last-child {
                    animation: stroke 0.4s cubic-bezier(0.65, 0, 0.45, 1) 0.6s forwards;
                }
            }
        }
    }

    .checkmark__check,
    .checkmark__error path {
        stroke-dasharray: 48;
        stroke-dashoffset: 48;
        transform-origin: 50% 50%;
    }

    @keyframes rotate {
        100% {
            transform: rotate(360deg);
        }
    }

    @keyframes loading {
        0% {
            stroke-dasharray: 1, 150;
            stroke-dashoffset: 0;
        }

        50% {
            stroke-dasharray: 90, 150;
            stroke-dashoffset: -35;
        }

        100% {
            stroke-dasharray: 90, 150;
            stroke-dashoffset: -124;
        }
    }

    @keyframes stroke {
        100% {
            stroke-dashoffset: 0;
        }
    }

    @keyframes scale {
        0%,
        100% {
            transform: none;
        }

        50% {
            transform: scale3d(1.1, 1.1, 1);
        }
    }

    @keyframes fill {
        100% {
            box-shadow: inset 0 0 0 30px var(--color);
            transition: box-shadow 1s;
        }
    }
</style>
