<template>
    <section class="info-section">
        <div v-for="(row, index) in contactInfo" :key="index" class="info">
            <span class="type gc-3 gr-2">
                <label for="type">Type</label>
                <vz-select v-model="row.type" @change="updateModelValue">
                    <option value="mobile">Mobiel</option>
                    <option value="phone">Telefoon</option>
                    <option value="fax">Fax</option>
                    <option value="email">Email</option>
                </vz-select>
            </span>
            <span class="value gc-8 gr-2">
                <label for="value">{{ row.type === 'email' ? 'Emailadres' : 'Nummer' }}</label>
                <input id="value" v-model="row.value" :type="row.type === 'email' ? 'email' : 'phone'" @input="updateModelValue" required />
            </span>
            <span class="add-row" @click="addRow" v-if="contactInfo.last()?.value">+</span>
        </div>
    </section>
</template>

<script setup lang="ts">
    import { UPDATE_MODEL_VALUE } from '@/constants/events';
    import { ContactInfo, type ContactInfoDTO, type ContactInfoType } from '@nietpluis/common/interfaces/common';
    import { onMounted, ref } from 'vue';

    const emit = defineEmits([UPDATE_MODEL_VALUE]);

    type Props = {
        defaultType?: ContactInfoType;
    };

    const props = withDefaults(defineProps<Props>(), {
        defaultType: 'mobile',
    });

    const contactInfo = ref<ContactInfoDTO[]>([]);

    onMounted(() => {
        addRow();
    });

    const updateModelValue = (): void => {
        emit(UPDATE_MODEL_VALUE, contactInfo.value);
    };

    const addRow = (): void => {
        if (contactInfo.value.last()?.value || !contactInfo.value.length) {
            contactInfo.value.push(new ContactInfo({ type: props.defaultType, value: '' }) as ContactInfoDTO);
        }
    };
</script>

<style scoped lang="scss">
    .info-section {
        .info {
            display: grid;
            grid-column: span 12;
            grid-column-gap: 1em;
            grid-row-gap: 1em;
            grid-template-columns: repeat(12, 1fr);

            .add-row {
                align-self: end;
                border: 1px solid hsl(var(--purple-400));
                border-radius: 50%;
                color: hsl(var(--purple-400));
                cursor: pointer;
                font-size: 1.25rem;
                grid-column: 12/13;
                grid-row: 2;
                height: 1.5rem;
                justify-self: center;
                line-height: 0.85;
                margin-bottom: 0.75rem;
                padding: 2px;
                text-align: center;
                transition-duration: 300ms;
                transition-property: color, border-color;
                width: 1.5rem;
                will-change: color, border-color;

                &:focus,
                &:hover {
                    border-color: hsl(var(--purple-700));
                    color: hsl(var(--purple-700));
                }
            }

            &:not(:last-of-type) {
                .add-row {
                    display: none;
                }
            }
        }
    }
</style>
