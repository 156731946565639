import { Format, SelectAll } from '@/directives/input';
import nl from '@/locales/nl.json';
import { abilitiesPlugin } from '@casl/vue';
import { autoAnimatePlugin } from '@formkit/auto-animate/vue';
import * as Sentry from '@sentry/vue';
import { createPinia } from 'pinia';
import { createApp } from 'vue';
import { createI18n } from 'vue-i18n';
import App from './App.vue';
import { AppAbility } from './config/ability';
import router from './router';

import '@nietpluis/common/utils';
import 'reflect-metadata';
import './assets/css/main.scss';

console.time('🕓 Application Loaded');

const app = createApp(App);
const pinia = createPinia();

const messages = {
    nl,
};

const i18n = createI18n({
    locale: 'nl',
    legacy: false,
    messages,
});

const isProduction = import.meta.env.VITE_SENTRY_ENVIRONMENT === 'production';

Sentry.init({
    app,
    dsn: import.meta.env.VITE_SENTRY_DSN,
    environment: import.meta.env.VITE_SENTRY_ENVIRONMENT,
    release: `${import.meta.env.VITE_APP_NAME}@${import.meta.env.VITE_APP_VERSION}`,
    integrations: [Sentry.browserTracingIntegration({ router }), Sentry.replayIntegration({ maskAllText: false })],
    trackComponents: true,
    debug: false, // !isProduction,
    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: isProduction ? 0.25 : 1.0,
    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for tracing.
    // We recommend adjusting this value in production

    // Set `tracePropagationTargets` to control for which URLs trace propagation should be enabled
    tracePropagationTargets: ['localhost', /^https:\/\/api\.niet-pluis\.nl/],

    // Capture Replay for 10% of all sessions,
    // plus for 100% of sessions with an error
    replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate: 1.0,

    attachProps: true,
    attachStacktrace: true,
    logErrors: true,
    beforeSend(event, hint) {
        // Check if it is an exception, and if so, log it.
        if (event.exception) {
            console.error(`[Exeption handled by Sentry]: (${hint.originalException})`, { event, hint });
        }
        // Continue sending to Sentry
        return event;
    },
});

app.mixin(Sentry.createTracingMixins({ trackComponents: true, timeout: 2000, hooks: ['activate', 'mount', 'update'] }));

// prettier-ignore
app.use(pinia)
    .use(router)
    .use(i18n)
    .use(autoAnimatePlugin)
    .use(abilitiesPlugin, AppAbility )
    .mount('#app');

// Directives
app.directive('format', Format);
app.directive('select-all', SelectAll);

console.timeEnd('🕓 Application Loaded');

// Prevent the browser from opening files that are dragged on the window
window.addEventListener('dragover', (e) => e.preventDefault(), false);
window.addEventListener('drop', (e) => e.preventDefault(), false);
