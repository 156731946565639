<template>
    <form @submit.prevent="handleSubmit">
        <VCard class="modal" large>
            <VCardTitle class="modal-title">{{ $t(`rolodex.${type}`) }} {{ $t('dialogs.add').toLowerCase() }}</VCardTitle>
            <VCardBody class="modal-content">
                <OrganizationName v-model="organization.name" @update:modelValue="onUpdateOrganizationName" required />
                <AddressForm v-model="organization.address" @update:modelValue="onUpdateAddress" v-if="type === 'practice'" />
                <ContactInfoForm v-model="organization.contact" @update:modelValue="onUpdateContactInfo" default-type="phone" />
            </VCardBody>
            <VCardActions class="modal-footer">
                <button secondary type="button" @click="cancel">{{ $t('dialogs.cancel') }}</button>
                <button type="submit">{{ $t('dialogs.add') }}</button>
            </VCardActions>
            <Loader :state="state" @finished="reset" />
        </VCard>
    </form>
</template>

<script setup lang="ts">
    import useAutofocus from '@/composables/use-autofocus';
    import { useMainStore } from '@/stores/main.store';
    import { usePracticeStore } from '@/stores/practice.store';
    import type { Address, ContactInfoDTO } from '@nietpluis/common/interfaces/common';
    import type { CreateOrganizationDTO, Organization, OrganizationType } from '@nietpluis/common/interfaces/organisation';
    import type { AxiosError } from 'axios';
    import { ref } from 'vue';
    import { useRouter } from 'vue-router';
    import type { LoadingState } from '../Loader.vue';

    type Props = {
        type?: OrganizationType;
    };

    const props = withDefaults(defineProps<Props>(), {
        type: 'practice',
    });

    useAutofocus();

    const emit = defineEmits(['cancel']);

    const mainStore = useMainStore();
    const practices = usePracticeStore();
    const router = useRouter();
    const state = ref<LoadingState>('idle');

    const organization = ref<CreateOrganizationDTO>({
        type: props.type,
        name: '',
        address: undefined,
        contact: [],
    });

    let reset = () => {
        state.value = 'idle';
    };

    const onUpdateOrganizationName = (value: string): void => {
        organization.value.name = value;
    };

    const onUpdateAddress = (value: Address): void => {
        organization.value.address = {
            street: value.street,
            houseNumber: value.houseNumber,
            houseNumberAddition: value.houseNumberAddition,
            zipcode: value.zipcode,
            city: value.city,
        };
    };

    const onUpdateContactInfo = (info: ContactInfoDTO[]): void => {
        organization.value.contact = info.map((contact: ContactInfoDTO) => {
            return {
                type: contact.type,
                value: contact.value,
            };
        });
    };

    const checkForMissingInformation = (): boolean => {
        if (!organization.value.name) {
            mainStore.addAlert({
                message: 'De naam van de organisatie is verplicht.',
                type: 'error',
            });

            state.value = 'idle';
            return false;
        }

        if (props.type === 'practice' && !organization.value.address) {
            mainStore.addAlert({
                message: 'Het adres van de praktijk is verplicht.',
                type: 'error',
            });

            state.value = 'idle';
            return false;
        }

        if (!organization.value.contact.length) {
            mainStore.addAlert({
                message: 'Er moet minimaal één contactinformatie ingevuld worden.',
                type: 'error',
            });

            state.value = 'idle';
            return false;
        }

        return true;
    };

    const handleSubmit = async (): Promise<void> => {
        state.value = 'loading';

        if (!checkForMissingInformation()) {
            reset = () => (state.value = 'idle');

            return;
        }

        const newOrganization: CreateOrganizationDTO = {
            type: props.type,
            name: organization.value.name,
            contact: organization.value.contact,
        };

        if (props.type === 'practice') {
            newOrganization.address = organization.value.address;
        }

        try {
            const { data }: { data: Organization } = await practices.create(newOrganization);

            if (data) {
                state.value = 'success';

                reset = async () => {
                    await practices.fetchOrganizations({ type: props.type });
                    router.push({ name: 'Contact', params: { filter: props.type, id: data.id } });

                    cancel();
                };
            }
        } catch (error) {
            state.value = 'error';
            const err: AxiosError = error as AxiosError;

            if (err.response) {
                mainStore.addAlert({
                    message: 'Het is niet gelukt om de organisatie toe te voegen.<br />Probeer later opnieuw.',
                    type: 'error',
                });
            } else if (err.request) {
                mainStore.addAlert({
                    message: 'Er kon geen verbinding gemaakt worden met de server.<br />Probeer later opnieuw.',
                    type: 'error',
                });
            } else {
                // Something happened in setting up the request and triggered an Error
                console.log('Error', err.message);
            }

            reset = () => {
                state.value = 'idle';
            };
        }
    };

    const cancel = () => {
        state.value = 'idle';
        emit('cancel');
    };
</script>

<style scoped lang="scss">
    .v-card-title {
        line-height: 1;
        margin-top: 0;
        padding: 0;
    }

    .v-card-body {
        display: grid;
        grid-template-rows: repeat(3, auto);
    }

    .modal-content {
        padding: 0;
    }

    .v-card-actions {
        padding: 0;
    }

    :deep(section) {
        display: grid;
        grid-gap: 1rem;
        grid-template-columns: repeat(12, 1fr);

        &:first-of-type {
            margin-top: -1rem;
        }

        label {
            @include font-base;

            font-weight: 400;
            margin-left: 4px;
        }
    }
</style>
