<template>
    <section class="practice">
        <div class="input-wrapper gc-6 gr-2">
            <label for="practiceName">{{ $t('general.name') }}</label>
            <input id="practiceName" v-model="name" type="text" @input="updateOrganizationName" v-bind="$attrs" />
        </div>
    </section>
</template>

<script setup lang="ts">
    import { UPDATE_MODEL_VALUE } from '@/constants/events';
    import { ref } from 'vue';

    const emit = defineEmits([UPDATE_MODEL_VALUE]);

    const name = ref('');

    const updateOrganizationName = (): void => {
        emit(UPDATE_MODEL_VALUE, name.value);
    };
</script>
