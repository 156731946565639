import type { ResultResponse } from '@/interfaces/Response';
import type { ActivateData, CreateUserDTO, User } from '@nietpluis/common/interfaces/person';
import axios, { type AxiosResponse } from 'axios';
import { wrapper } from 'axios-cookiejar-support';
import { defineStore } from 'pinia';
import { CookieJar } from 'tough-cookie';

wrapper(axios);

const jar = new CookieJar();
axios.defaults.withCredentials = true;

export const useUserStore = defineStore('user', {
    state: () => ({
        users: [] as User[],
        user: undefined as User | undefined,
    }),
    getters: {
        getUsers: (state) => state.users,
        getRole: (state) => (user: User) => {
            if (user.role.name.toLowerCase() === 'user') {
                return 'Gebruiker';
            }

            return user.role.name;
        },
    },
    actions: {
        async fetchUsers(): Promise<void> {
            const { data }: { data: ResultResponse<User> } = await axios.get('/user', { jar, withCredentials: true });

            this.users = data.results;
        },

        async fetchUserById(id: string, force = false): Promise<void> {
            if (!id) {
                throw Error('No user id given');
            }

            const foundUser = this.users.find((user) => user.id === id);

            if (!foundUser || force) {
                const { data }: { data: User } = await axios.get(`/user/${id}`, { jar, withCredentials: true });
                this.user = data;
            } else {
                this.user = foundUser;
            }
        },

        async create(user: CreateUserDTO): Promise<AxiosResponse> {
            return axios.post('/user', user);
        },

        async activate(activateData: ActivateData): Promise<{ accessToken: string }> {
            const { data } = await axios.post('/user/activate', {
                ...activateData,
            });

            return data;
        },

        async forgotPassword(email: string): Promise<AxiosResponse> {
            return axios.post('/user/forgot-password', { email });
        },

        async resetPassword(options: {
            userId: string;
            token: string;
            password: string;
            passwordConfirmation: string;
        }): Promise<AxiosResponse> {
            return axios.post('/user/reset-password', {
                userId: options.userId,
                token: options.token,
                password: options.password,
                passwordConfirmation: options.passwordConfirmation,
            });
        },

        async checkToken(token: string): Promise<AxiosResponse> {
            return axios.get(`/user/token/${token}`, { jar, withCredentials: true });
        },

        async checkIfActive(id: string): Promise<AxiosResponse> {
            return axios.get(`/user/active/${id}`, { jar, withCredentials: true });
        },

        async resendActivation(options: { id: string; email: string }): Promise<AxiosResponse> {
            return axios.post('/user/resend-activation', {
                id: options.id,
                email: options.email,
            });
        },
    },
});
