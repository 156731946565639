<template>
    <div class="select">
        <div class="select-chevron">
            <VZIcon>expand_more</VZIcon>
        </div>
        <select v-model="model" v-bind="$attrs">
            <slot></slot>
        </select>
    </div>
</template>

<script setup lang="ts">
    const model = defineModel<string>();
</script>

<style scoped lang="scss">
    .select {
        .select-chevron {
            // background-color: hsl(var(--grey-600));
            font-size: 1.5rem;

            // clip-path: polygon(100% 0%, 0 0%, 50% 100%);
            // content: '';
            grid-area: select;

            // height: 0.5em;34k
            justify-self: end;
            margin-right: 0.5rem;
            width: 0.8em;
        }
    }
</style>
