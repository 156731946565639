import { AbilityBuilder, createMongoAbility, type MongoAbility } from '@casl/ability';
import type { User } from '@nietpluis/common/interfaces/person';

export type Actions = 'manage' | 'create' | 'read' | 'update' | 'delete';
export type Subjects = 'ContactInfo' | 'ReportUpdate' | 'Report' | 'User' | 'Patient' | 'GP' | 'Practice' | 'Homecare' | 'all';

export type Abilities = MongoAbility<[Actions, Subjects]>;
export const AppAbility = createMongoAbility<[Actions, Subjects]>();

export function defineRulesFor(user: User) {
    const { can, rules } = new AbilityBuilder(createMongoAbility);

    if (user.role?.name === undefined) {
        return;
    }

    if (user.role.name === 'Administrator') {
        can('manage', 'all');
    } else if (user.role.name === 'Developer') {
        can('manage', 'all');
    } else if (user.role.name === 'Reporter') {
        can('read', 'Report', { reporter: { id: user.id } });
        can('create', 'Report');
        can('read', 'ContactInfo');
    } else {
        can('manage', 'Report');
        can('manage', 'ReportUpdate');
        can('manage', 'Patient');
        can('read', 'ContactInfo');
    }

    return rules;
}

export function buildAbilityFor(user: User) {
    return createMongoAbility(defineRulesFor(user));
}
