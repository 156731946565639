<template>
    <router-view v-slot="{ Component }" key="page">
        <transition name="fade-quick" mode="out-in">
            <component :is="Component" />
        </transition>
    </router-view>
    <!-- <Tooltip /> -->
    <div
        class="alert"
        v-for="(alert, index) in mainStore.alerts"
        :key="alert.timestamp"
        :class="[{ active: alert.active, leaving: alert.leaving }, alert.type]"
        :style.once="`margin-bottom: calc(${mainStore.count - 1 - index} * 4rem)`">
        <span class="alert__message" v-html="alert.message"></span>
    </div>
</template>

<script setup lang="ts">
    import { useMainStore } from './stores/main.store';

    const mainStore = useMainStore();
</script>

<style scoped lang="scss">
    .alert-enter-active {
        transition-duration: 1s, 1s, 300ms;
        transition-property: opacity, transform, margin-bottom;
        transition-timing-function: ease-in-out;
        will-change: opacity, transform;
    }

    .alert-leave-active {
        transition-duration: 600ms, 800ms;
        transition-property: opacity, transform;
        transition-timing-function: ease-in, ease-in;
    }

    .alert-enter-from {
        opacity: 0;
        transform: translateY(-60px);
    }

    .alert-leave-to {
        opacity: 0;
        transform: translateY(-30px);
    }

    .centered {
        display: grid;
        place-items: center;
    }

    .alert {
        bottom: 4rem;
        display: grid;
        grid-area: alert;
        opacity: 0;
        place-items: center;
        position: absolute;
        transform: translateY(60px);
        transition-duration: 1s, 1s, 1100ms;
        transition-property: opacity, transform, margin-bottom;
        transition-timing-function: ease-in-out;
        width: 100vw;
        z-index: 999;

        &.active {
            opacity: 1;
            transform: translateY(0);
        }

        &.leaving {
            opacity: 0;
            transform: translateY(-90px);
            transition-duration: 600ms, 800ms, 1100ms;
            transition-property: opacity, transform, margin-bottom;
            transition-timing-function: ease-in, ease-in;
        }

        .alert__message {
            border-radius: 6px;
            margin-left: 8px;
            margin-top: 2rem;
            padding: 1rem;
            text-align: center;
        }

        &.error .alert__message {
            background-color: hsl(var(--red-200));
            border: 1px solid hsl(var(--red-600));
            color: hsl(var(--red-600));
        }

        &.success .alert__message {
            background-color: #e6f2f1;
            border: 1px solid #299e90;
            color: #299e90;
        }

        &.info .alert__message {
            background-color: #e8f2fe;
            border: 1px solid #2097f3;
            color: #2097f3;
        }
    }

    .overlay-container {
        contain: layout;
        display: contents;
        inset: 0;
        pointer-events: none;
        position: absolute;
    }
</style>
