<template>
    <form @submit.prevent="handleSubmit">
        <VCard class="modal" large>
            <VCardTitle class="modal-title">
                {{ props.organization ? $t('rolodex.patient-alternative') : $t('rolodex.patient') }} toevoegen
            </VCardTitle>
            <VCardBody class="modal-content scrollGradient">
                <Personalia v-model="patient" @update:modelValue="onUpdatePatient" type="patient" />
                <FindGP v-model="gpId" @update:modelValue="onUpdateGP" />
                <section id="homecare" v-if="!props.organization">
                    <span class="gc-3">
                        <label for="organization">{{ $t('rolodex.homecare') }}</label>
                        <vz-select v-model="organizationId" id="organization" required>
                            <option value="">-</option>
                            <option
                                v-for="organization in organizations"
                                :key="organization.id"
                                :value="organization.id"
                                :selected="props.organization === organization.id">
                                {{ organization.name }}
                            </option>
                        </vz-select>
                    </span>
                </section>
            </VCardBody>
            <VCardActions class="modal-footer">
                <button secondary type="button" @click="cancel">Annuleren</button>
                <button type="submit">Toevoegen</button>
            </VCardActions>
            <Loader :state="state" @finished="reset" />
        </VCard>
    </form>
</template>

<script setup lang="ts">
    import useAutofocus from '@/composables/use-autofocus';
    import { useMainStore } from '@/stores/main.store';
    import { usePatientStore } from '@/stores/patient.store';
    import { usePracticeStore } from '@/stores/practice.store';
    import type { CreateDetailsDTO, CreatePatientDTO, Patient } from '@nietpluis/common/interfaces/person';
    import type { AxiosError } from 'axios';
    import { onMounted, reactive, ref } from 'vue';
    import { useRouter } from 'vue-router';
    import type { Personalia } from '../Contact/Personalia.vue';
    import type { LoadingState } from '../Loader.vue';

    const emit = defineEmits<{
        (e: 'cancel'): void;
    }>();

    const props = defineProps({
        organization: {
            type: String,
            required: false,
        },
    });

    useAutofocus();
    const mainStore = useMainStore();
    const patients = usePatientStore();
    const organizationStore = usePracticeStore();
    const router = useRouter();

    let patient = reactive<CreatePatientDTO>({
        type: 'patient',
        details: {
            firstName: '',
            lastName: '',
            gender: undefined,
            initials: '',
            gpId: undefined,
            address: undefined,
            contact: [],
        },
        organizationId: undefined,
        dob: new Date(),
    });

    const gpId = ref('');
    const organizationId = ref(props.organization ?? '');
    const organizations = ref(organizationStore.homecareOrganizations);

    const state = ref<LoadingState>('idle');

    onMounted(async () => {
        await organizationStore.fetchOrganizations({ type: 'homecare' });
        organizations.value = organizationStore.homecareOrganizations;
    });

    const onUpdatePatient = (value: Personalia<CreatePatientDTO>): void => {
        patient.details = value.details;

        if (value.dob) {
            patient.dob = value.dob;
        }
    };

    const onUpdateGP = (value: string): void => {
        patient.details.gpId = value;
    };

    const handleSubmit = async (): Promise<void> => {
        state.value = 'loading';

        if (!patient.details) {
            state.value = 'error';

            mainStore.addAlert({
                message: 'Helaas is er iets fout gegaan. Probeer later opnieuw.<br />Een rapport van de foutmelding is doorgestuurd.',
                type: 'error',
            });

            reset = () => {
                state.value = 'idle';
            };

            // throw new Error('Patient details are missing');
        }

        const details: CreateDetailsDTO = {
            firstName: patient.details.firstName,
            lastName: patient.details.lastName,
            initials: patient.details.initials,
            gender: patient.details.gender,
            gpId: gpId.value,
            contact: patient.details.contact,
        };

        const newPatient: CreatePatientDTO = {
            type: 'patient',
            dob: patient.dob,
            organizationId: organizationId.value,
            details,
        };

        try {
            const { data }: { data: Patient } = await patients.create(newPatient);

            if (data) {
                state.value = 'success';

                reset = async () => {
                    await patients.fetchPatients();
                    router.push({ name: 'Contact', params: { filter: 'patient', id: data.id } });
                    cancel();
                };
            }
        } catch (error) {
            state.value = 'error';
            const err: AxiosError = error as AxiosError;

            if (err.response) {
                mainStore.addAlert({
                    message: 'Het is niet gelukt om deze persoon toe te voegen.<br />Probeer later opnieuw.',
                    type: 'error',
                });
            } else if (err.request) {
                mainStore.addAlert({
                    message: 'Er kon geen verbinding gemaakt worden met de server.<br />Probeer later opnieuw.',
                    type: 'error',
                });
            } else {
                // Something happened in setting up the request and triggered an Error
                console.log('Error', err.message);
            }

            reset = () => {
                cancel();
            };
        }
    };

    let reset = () => {
        state.value = 'idle';
    };

    const cancel = () => {
        state.value = 'idle';
        emit('cancel');
    };
</script>

<style scoped lang="scss">
    .v-card-title {
        line-height: 1;
        margin-top: 0;
        padding: 0;
    }

    .v-card-body {
        display: grid;
        grid-template-rows: repeat(3, auto);
    }

    .modal-content {
        padding: 0;
    }

    .v-card-actions {
        padding: 0;
    }

    :deep(section) {
        display: grid;
        grid-column-gap: 1rem;
        grid-template-columns: repeat(12, 1fr);
        row-gap: 1rem;

        &:first-of-type {
            margin-top: -1rem;
        }

        label {
            @include font-base;

            font-weight: 400;
            margin-left: 4px;
        }
    }

    .practice {
        .results {
            margin-top: 0.5rem;
            min-width: 320px;

            strong {
                color: hsl(var(--theme-color));
            }

            .result {
                cursor: pointer;
                padding: 0.5rem 0;

                .name {
                    margin-left: 1rem;
                }

                &.result-active {
                    color: hsl(var(--theme-color));
                }
            }
        }
    }
</style>
