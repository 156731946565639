export const EVENT_NAME_CHANGE = 'change';
export const EVENT_NAME_CHANGED = 'changed';
export const EVENT_LOGGED_IN = 'logged-in';
export const EVENT_ALERT = 'alert';
export const EVENT_FILTER_CHANGED = 'filter-changed';
export const UPDATE_MODEL_VALUE = 'update:modelValue';
export const KEYPRESS_ESC = 'esc';

export const CONTACTLIST_CONTACT_SELECTED = 'contact-selected';

// export const swEventType = 'service-worker-event' as const;

// "CustomEvent" comes from 'lib.dom.d.ts' (tsconfig.json)
class ServiceWorkerEvent extends CustomEvent<SWEventDetails> {
    constructor(name: string, detail: SWEventDetails) {
        super(name, { detail });
    }
}

interface SWEventDetails {
    registration: ServiceWorkerRegistration;
}

export default ServiceWorkerEvent;

// augment your global namespace
// here, we're augmenting 'WindowEventMap' from 'lib.dom.d.ts' 👌
declare global {
    interface WindowEventMap {
        [key: string]: ServiceWorkerEvent;
    }
}
