import type { ContactType } from '@nietpluis/common/interfaces/person';
import { defineStore } from 'pinia';

export type ReportFilter = 'open' | 'past-day' | 'me';

export interface Filters {
    report: ReportFilter;
    rolodex: Omit<ContactType, 'patient-alternative'>;
}

export interface FilterSlider {
    width: number;
    left: number;
}

export const useFilterStore = defineStore('filter', {
    state: () => ({
        report: 'open' as ReportFilter,
        rolodex: 'user' as ContactType,
        slider: {
            width: localStorage.getItem('filter-slider-width') ?? 0,
            left: localStorage.getItem('filter-slider-left') ?? 0,
        } as FilterSlider,
    }),
    getters: {},
    actions: {
        setReportFilter(filter: ReportFilter): void {
            this.report = filter;
            this.saveFilters();
        },

        setRolodexFilter(filter: ContactType): void {
            if (!filter) {
                return;
            }
            this.rolodex = filter;
            this.saveFilters();
        },

        saveFilters(): void {
            localStorage.setItem(
                'filters',
                JSON.stringify({
                    report: this.report,
                    rolodex: this.rolodex,
                }),
            );
        },

        saveSliderConfig(config: FilterSlider): void {
            localStorage.setItem('filter-slider-width', config.width.toString());
            localStorage.setItem('filter-slider-left', config.left.toString());
        },
    },
});
