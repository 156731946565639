<template>
    <section class="personalia">
        <span class="initials gc-2 gr-2">
            <label for="initials">Initialen</label>
            <input id="initials" v-model.lazy="initials" type="text" autocomplete="initials" required />
        </span>
        <span class="firstName gc-4 gr-2">
            <label for="firstName">Voornaam</label>
            <input
                id="firstName"
                v-model.trim="person.details.firstName"
                type="text"
                v-format.non-digit
                @input="updateModelValue"
                required />
        </span>
        <span class="lastName gc-6 gr-2">
            <label for="lastName">Achternaam</label>
            <input id="lastName" v-model.trim="person.details.lastName" type="text" v-format.non-digit @input="updateModelValue" required />
        </span>
        <span class="gender gc-3 gr-3" v-if="gender">
            <label for="gender">Geslacht</label>
            <vz-select v-model="person.details.gender">
                <option value="">-</option>
                <option value="male">Man</option>
                <option value="female">Vrouw</option>
                <option value="other">Anders</option>
            </vz-select>
        </span>
        <span class="dob gc-4 gr-3" v-if="dob">
            <label for="dob">Geboortedatum</label>
            <input id="dob" v-model="person.dob" type="date" @input="updateModelValue" />
        </span>
    </section>
</template>

<script setup lang="ts">
    import { UPDATE_MODEL_VALUE } from '@/constants/events';
    import type { CreatePatientDTO, CreatePersonDTO } from '@nietpluis/common/interfaces/person';
    import type { PartialBy } from '@nietpluis/common/utils';
    import { computed, reactive } from 'vue';

    type Props = {
        dob?: boolean;
        gender?: boolean;
        type: 'user' | 'gp' | 'patient';
    };

    const props = withDefaults(defineProps<Props>(), {
        dob: true,
        gender: true,
    });

    const initials = computed({
        get() {
            return person.details.initials;
        },

        set(initials: string) {
            person.details.initials =
                initials
                    .replace(/\d/g, '')
                    .replace(/\./g, '')
                    .split('')
                    .map((initial: string) => initial.toUpperCase())
                    .join('.') + '.';

            if (person.details.initials.length === 1) {
                person.details.initials = '';
            }

            updateModelValue();
        },
    });

    const emit = defineEmits([UPDATE_MODEL_VALUE]);

    export type Personalia<T> = T extends CreatePatientDTO ? PartialBy<CreatePatientDTO, 'dob'> : CreatePersonDTO;
    const defaultPersonalia: Personalia<CreatePersonDTO> = {
        type: props.type,
        details: {
            firstName: '',
            lastName: '',
            initials: '',
            gender: undefined,
            gpId: undefined,
            contact: [],
        },
        organizationId: '',
    };

    const person = reactive<Personalia<CreatePatientDTO>>({
        ...defaultPersonalia,
    });

    const updateModelValue = (): void => {
        emit(UPDATE_MODEL_VALUE, person);
    };
</script>
