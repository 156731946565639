import { trimDigits, trimWhiteSpace } from '@nietpluis/common/interfaces/common';
import type { DirectiveBinding } from 'vue';

export const Format = {
    updated(el: HTMLInputElement, binding: DirectiveBinding) {
        if (!el.value) return;

        el.value = el.value.replace(/ +/, ' ');

        if (binding.modifiers.trim) {
            el.value = trimWhiteSpace(el.value);
        }

        if (binding.modifiers['non-digit']) {
            el.value = trimDigits(el.value);
        }

        if (binding.modifiers.capitalize) {
            let names = el.value.split(' ');

            names = names.map((name) => (name.length ? name[0].toUpperCase() + name.slice(1) : ''));
            el.value = names.join(' ');
        }

        if (binding.modifiers.dash) {
            el.value = el.value.replace(/-+/g, '-');
            el.value = el.value.replace(/ +/, '-');
        }
    },
};

export const SelectAll = {
    mounted(el: HTMLInputElement) {
        el.addEventListener('focus', () => {
            el.select();
        });
    },
    beforeUnmount(el: HTMLInputElement) {
        el.removeEventListener('focus', () => {
            el.select();
        });
    },
};
