import type { ResultResponse } from '@/interfaces/Response';
import type { CreatePatientDTO, Patient } from '@nietpluis/common/interfaces/person';
import axios, { type AxiosResponse } from 'axios';
import { defineStore } from 'pinia';

export const usePatientStore = defineStore('patient', {
    state: () => ({
        patients: [] as Patient[],
        patient: undefined as Patient | undefined,
    }),
    getters: {
        getPatients: (state): Patient[] => state.patients as Patient[],
        hasActiveReports: (state): boolean => state.patient?.reports.some((report) => report.isActive) ?? false,
    },
    actions: {
        async fetchPatients(options?: { organizationId?: string }): Promise<void> {
            const queryUrl = '/person/?type=patient';

            if (options?.organizationId) {
                queryUrl.concat(`&organizationId=${options.organizationId}`);
            }

            const { data }: { data: ResultResponse<Patient> } = await axios.get(queryUrl);

            this.patients = data.results;
        },

        async fetchPatientById(id: string): Promise<void> {
            if (!id) {
                throw Error('No patient id given');
            }

            const foundPatient = this.patients.find((patient) => patient.id === id);

            if (!foundPatient) {
                const { data }: { data: Patient } = await axios.get(`/person/${id}`);
                this.patient = data;
            } else {
                this.patient = foundPatient;
            }
        },

        async create(patient: CreatePatientDTO): Promise<AxiosResponse> {
            return axios.post('/person', patient);
        },
    },
});
