<template>
    <section class="user-options">
        <span class="user-email gc-12 gr-2">
            <label for="user-email">Emailadres</label>
            <input
                id="user-email"
                v-model="email"
                type="email"
                @input="updateEmail"
                required
                autocomplete="off"
                :class="{ dirty: email != null || error === 'email' }"
                :invalid="error === 'email'" />
            <InputError :show="error === 'email'" error-type="email.in-use" />
        </span>
        <span class="user-role gc-3 gr-3" v-if="role">
            <label for="user-role">{{ $t('roles.role') }}</label>
            <vz-select id="user-role" v-model="roleId" @change="updateRoleId" required>
                <option value="" selected>-</option>
                <option :value="role.id" v-for="role in roles" :key="role.id">{{ $t(`roles.${role.name}`) }}</option>
            </vz-select>
        </span>
        <span class="user-homecare gc-3 gr-3" v-if="roleId === reporterRoleId">
            <label for="organization">{{ $t('rolodex.homecare') }}</label>
            <vz-select v-model="organization" id="organization" @change="updateOrganizationId" required>
                <option value="">-</option>
                <option v-for="organization in organizations" :key="organization.id" :value="organization.id">
                    {{ organization.name }}
                </option>
            </vz-select>
        </span>
    </section>
</template>

<script setup lang="ts">
    import useAutofocus from '@/composables/use-autofocus';
    import { usePracticeStore } from '@/stores/practice.store';
    import { useRoleStore, type Role } from '@/stores/role.store';
    import { onMounted, ref, watch, type Ref } from 'vue';

    interface Props {
        role?: boolean;
        error?: 'email';
    }

    const props = withDefaults(defineProps<Props>(), {
        role: false,
    });

    useAutofocus();
    const organizationStore = usePracticeStore();
    const roleStore = useRoleStore();

    const roles: Ref<Role[]> = ref([]);
    const organizations = ref(organizationStore.homecareOrganizations);

    const emit = defineEmits(['update:email', 'update:roleId', 'update:organizationId']);
    const roleId = ref('');
    const reporterRoleId = ref('');
    const email = ref<string | null>(null);
    const organization = ref<string | undefined>('');
    const error = ref<'email' | undefined>();

    watch(
        () => props.error,
        (value) => {
            error.value = value;
        },
    );

    onMounted(async () => {
        await roleStore.fetchRoles();
        if (!organizations.value.length) {
            await organizationStore.fetchOrganizations({ type: 'homecare' });
            organizations.value = organizationStore.homecareOrganizations;
        }

        roles.value = roleStore.getRoles;
        // roleId.value = roles.value.first().id;
        reporterRoleId.value = roles.value.find((role) => role.name.toLowerCase() === 'reporter')?.id ?? '';
    });

    const updateEmail = (): void => {
        error.value = undefined;
        emit('update:email', email.value);
    };

    const updateRoleId = (): void => {
        if (roleId.value !== reporterRoleId.value) {
            organization.value = undefined;
        }

        emit('update:roleId', roleId.value);
    };

    const updateOrganizationId = (): void => {
        emit('update:organizationId', organization.value);
    };
</script>
