<template>
    <div class="create-button">
        <VDialog v-model="newContactDialogActive">
            <template #activator="{ on }">
                <button class="button button--secondary" @click="on">{{ createButtonText }}</button>
            </template>

            <Component
                :is="newContactDialog.component"
                v-bind="newContactDialog?.props"
                @cancel="cancel"
                @keydown.esc="newContactDialogActive = false" />
        </VDialog>
    </div>
</template>

<script setup lang="ts">
    import { computed, ref } from 'vue';

    import NewGP from '@/components/Dialogs/NewGP.vue';
    import NewOrganization from '@/components/Dialogs/NewOrganization.vue';
    import NewPatient from '@/components/Dialogs/NewPatient.vue';
    import NewUser from '@/components/Dialogs/NewUser.vue';
    import type { ContactType } from '@nietpluis/common/interfaces/person';

    import { useI18n } from 'vue-i18n';

    type Props = {
        type: ContactType;
        organization?: string;
    };

    const cancel = () => (newContactDialogActive.value = false);
    const newContactDialogActive = ref(false);
    const { t } = useI18n();

    const props = defineProps<Props>();
    const createButtonText = computed((): string => {
        const type = t(`rolodex.${props.type}`, 1);
        return `${type} toevoegen`;
    });

    const newContactDialog = computed(() => {
        switch (props.type) {
            case 'gp':
                return { component: NewGP };
            case 'patient':
                return { component: NewPatient };
            case 'patient-alternative':
                return {
                    component: NewPatient,
                    props: {
                        alternative: true,
                        organization: props.organization ?? undefined,
                    },
                };
            case 'user':
                return { component: NewUser };
            case 'practice':
                return {
                    component: NewOrganization,
                    props: {
                        type: 'practice',
                    },
                };
            case 'homecare':
                return {
                    component: NewOrganization,
                    props: {
                        type: 'homecare',
                    },
                };
            default:
                return { component: NewUser };
        }
    });
</script>

<style scoped lang="scss">
    .create-button {
        display: flex;
        justify-content: flex-end;

        &[text] {
            .button {
                background-color: transparent;
                border: none;
                border-bottom: 1px solid transparent;
                border-radius: 0;
                box-shadow: none;
                color: hsl(var(--theme-color));
                margin-top: 1rem;
                padding: 0;
                padding-bottom: 0.5rem;
                transition: color 300ms ease-in-out, border-color 300ms ease-in-out;

                &:hover,
                &:focus {
                    border-color: hsl(var(--purple-400));
                    color: hsl(var(--purple-400));
                }
            }
        }
    }
</style>
