export type DateLike = Date | string;

const REGEX_FORMAT = /\[([^\]]+)]|Y{1,4}|M{1,4}|D{1,2}|d{1,4}|H{1,2}|h{1,2}|a|A|m{1,2}|s{1,2}|Z{1,2}|SSS/g;

const months = ['januari', 'februari', 'maart', 'april', 'mei', 'juni', 'juli', 'augustus', 'september', 'oktober', 'november', 'december'];

const convertToMonth = (month: number): string => months[month];

export function formatDateTime(date?: DateLike): string {
    if (!date) {
        return '-';
    }

    const formattedDate = formatDate(new Date(date), 'DD MMM YYYY');
    const formattedTime = formatDate(new Date(date), 'HH:mm');

    return `${formattedDate} om ${formattedTime}`;
}

export function formatDate(dateLike?: DateLike, format = 'DD-MM-YYYY'): string {
    if (!dateLike) {
        return '-';
    }

    const date = new Date(dateLike);

    const years = date.getFullYear();
    const month = date.getMonth();
    const days = date.getDate();
    const hours = date.getHours();
    const minutes = date.getMinutes();
    const seconds = date.getSeconds();
    const milliseconds = date.getMilliseconds();

    const matches: Record<string, string | number> = {
        YY: String(years).slice(-2),
        YYYY: years,
        M: month + 1,
        MM: `${month + 1}`.padStart(2, '0'),
        MMM: convertToMonth(month),
        D: String(days),
        DD: `${days}`.padStart(2, '0'),
        H: String(hours),
        HH: `${hours}`.padStart(2, '0'),
        h: `${hours % 12 || 12}`.padStart(1, '0'),
        hh: `${hours % 12 || 12}`.padStart(2, '0'),
        m: String(minutes),
        mm: `${minutes}`.padStart(2, '0'),
        s: String(seconds),
        ss: `${seconds}`.padStart(2, '0'),
        SSS: `${milliseconds}`.padStart(3, '0'),
    };
    return format.replace(REGEX_FORMAT, (match, $1) => $1 || matches[match]);
}
